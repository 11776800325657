window.jordanb2b = {};
jordanb2b.Settings = {
    DM: false,
    Currency: {},
    GridStackOptions: null,
    ShowSpinner: null,
    isShopDesignerAdmin: false,
    MenuFlyout: 500,
    MenuFlyoutIntern:0,
    isTablet:null,
    isMobile:null,
    ShopDesigner: {
        GridStack: {
            Float: null
        }
    }
};
window.jordanb2b.util = {
    isTouch: function () {
        return this.isEventSupported('msgesturechange', true) || this.isEventSupported('touchstart');
    },
    isEventSupported: function (eventName, inWindow) {
        if (inWindow) {
            return "on" + eventName in window;
        }
        var el = document.createElement("body"[eventName] || "div");
        var isSupported = "on" + eventName.toLowerCase() in el || top.Event && typeof top.Event == "object" && eventName.toUpperCase() in top.Event;
        el = null;
        return isSupported;
    },
    showLoadingOverlay: function () {
        //TODO
    },
    hideLoadingOverlay: function () {
        //TODO
    },
    updateFormStyles: function (el) {
        //HTMLLabelContainer
        $('.form-group, .form-group-validation', el).each(function () {
            $(this).find('input').not('[type="checkbox"],[type="radio"]').addClass('form-control');
            var hasErrors = $(this).find('input.error, textarea.error').length > 0;
            if (hasErrors) {
                $(this).addClass('has-error');
            }
        });
        //checkbox
        $('div.checkbox', el).each(function () {
            var hasErrors = $(this).find('input.error').length > 0;
            if (hasErrors) {
                $(this).addClass('has-error');
            }
        });
        $.configDatepicker();
    },
    initAll: function () {
        this.initSwiper();
        this.initSlick();
        this.initSlider();
        this.initSelect2();
        this.initFilterAccordion();
        this.initTooltip();
        this.initPopover();
        this.initZoomImage();
        this.initMatchHeight();
        this.initMobileMenu();
        this.initPopupCartHeight();
    },
    initSwiper: function () {
        $('.jd-popup-list-swiper').each(function () {
            var slidesPerView = $(this).data('slidesPerView') || 3;
            var autoHeight = $(this).data('autoHeight') === false ? false : true;
            var swiper = new Swiper($(this), {
                pagination: '.swiper-pagination',
                direction: 'vertical',
                slidesPerView: slidesPerView,
                autoHeight: autoHeight,
                spaceBetween: 10,
                mousewheelControl: true,
                nextButton: '.jd-popup-list-next',
                prevButton: '.jd-popup-list-prev',
                onInit: function (swiper) {
                    $('#shoppincartPopUpInner .swiper-slide, #favoritesPopUpInner .swiper-slide').each(function (index, rawElement) {
                        var element = $(rawElement);
                        var liElement = element.children();
                        liElement.addClass('bold');
                        var boldHeight = liElement.css('height');
                        liElement.removeClass('bold');
                        element.height(parseInt(boldHeight) + 5);
                    });
                }
            });
        });
    },
    initSlick: function () {
        //product detail page
        $('.productslider:visible:not(".slick-initialized")').slick({
            infinite: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            centerMode: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 970,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $('.productslider_cart').slick({
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 325,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                    }
                },
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 2,
                        centerMode: false,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 1240,
                    settings: {
                        infinite: false,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        centerMode: false
                    }
                }]
        });
    },
    initSlider: function () {
        $(".sliderelement .form-slider").slider();
        $(".sliderelement .form-slider-mobile").slider({
            tooltip: 'always',
            'tooltip_position': 'bottom'
        });
    },
    initSelect2: function () {
        $('select.select2').select2({theme: 'bootstrap', minimumResultsForSearch: -1});
        //variant selection
        $('select.productvariant-selection').initProductVariantSelection();
        $('b[role="presentation"]').hide();
        $('.select2-selection__arrow').append('<i class="fas fa-sort-down"></i>');
    },
    initFilterAccordion: function () {
        $('#collapse-filter').on('hide.bs.collapse', function () {
            $("#filter-settings").appendAttributeButtons('show');
        }).on('show.bs.collapse', function () {
            $("#filter-settings").appendAttributeButtons('hide');
        });
    },
    initTooltip: function () {
        $('[data-toggle="tooltip"]').tooltip();
    },
    initPopover: function () {
        $('[data-toggle="popover"]').popover();
    },
    initZoomImage: function () {
        if ($("body").width() < 768) {
            $("#product-image").elevateZoom({
                gallery: 'product-image-gallery',
                cursor: 'pointer',
                galleryActiveClass: 'active',
                loadingIcon: 'http://www.elevateweb.co.uk/spinner.gif',
                zoomWindowWidth: 0,
                zoomWindowHeight: 0,
                lensBorder: 0,
                disableZoom: true
            });
        } else {
            $("#product-image").elevateZoom({
                gallery: 'product-image-gallery',
                cursor: 'pointer',
                galleryActiveClass: 'active',
                imageCrossfade: true,
                loadingIcon: 'http://www.elevateweb.co.uk/spinner.gif',
                zoomWindowWidth: 570,
                zoomWindowHeight: 570
            });
        }
    },
    initMatchHeight: function () {
        $('.variants-container').matchHeight({byRow: true});
        if ($('#products').length > 0) {
            //match height after images has loaded
            $('#products').waitForImages(function () {
                $('.variants-container').matchHeight({byRow: true});
            });
        } else {
            $('.panel-product').matchHeight();
        }
    },
    initMobileMenu: function () {
        $("#mobileCategoryMenu").removeAttr('style');
        $("#mobileCategoryMenu").mmenu({
                setSelected: {
                    hover: true
                },
                offCanvas: {
                    position: "right",
                    zposition: "next"

                },
                iconPanels: true,
                navbar: {
                    title: 'Navigation'
                },
                navbars: {
                    content: "breadcrumbs",
                    position: "top",
                    titleLink: "anchor"
                }
            }, {
                offCanvas: {
                    wrapPageIfNeeded: true

                },
                classNames: {
                    fixedElements: {
                        fixed: "Fixed"
                    }
                }
            }
        );
    },
    isJSON: function (string) {
        if (typeof string != 'string') {
            string = JSON.stringify(string);
        }
        try {
            JSON.parse(string.replace(/(\r\n|\n|\r)/gm, ""));
            return true;
        } catch (e) {
            return false;
        }
    },
    changeUrl: function (page, url) {
        if (typeof (history.pushState) != "undefined") {
            var obj = {
                Page: page,
                Url: url
            };
            history.pushState(obj, obj.Page, obj.Url);
            $(window).bind("popstate", function (event) {
                document.location.reload();
            });
        }
    },
    scroll: function () {
        var target = $("#startProducts");
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - 100
            }, 1000);
        }
    },
    getIEVersion: function () {
        var sAgent = window.navigator.userAgent;
        var Idx = sAgent.indexOf("MSIE");
        // If IE, return version number.
        if (Idx > 0) {
            return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
        } else if (!!navigator.userAgent.match(/Trident\/7\./))
            // If IE 11 then look for Updated user agent string.
            return 11;

        else {
            //It is not IE
            return 0;
        }
    },
    wait: function (count) {
        var start = Date.now();
        var stop = Date.now();
        var res = stop - start;
        while (res < count) {
            stop = Date.now();
            res = stop - start;
        }
    },
    getSelectedFilterElements: function (visible) {
        if (typeof visible === "undefined") {
            visible = true
        }
        var selectors = 'select.attribute-filter.selected,input.form-slider.selected';
        if (true === visible) {
            return $(selectors, 'form:visible');
        }
        return $(selectors);
    },
    initPopupCartHeight: function () {
        var lastOrderedCol = $('.col-lastordered-products');
        if (lastOrderedCol.length > 0) {
            var hl = lastOrderedCol.height();
            var hr = $('.col-cart-products').height();
            $('#shoppincartPopUpInner').height((hr > hl) ? hr : hl);
        }
    },
    initDocumentHash: function () {
        var hash = document.location.hash;
        if (hash) {
            //bookmarks
            var element = $('.collapsed[data-target="' + hash + '"]');
            if (element.length > 0) {
                element.click();
                return;
            }
        }
    },
    removeUrlHash: function () {
        let uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            let clean_uri = uri.substring(0, uri.indexOf("#"));
            window.history.replaceState({}, document.title, clean_uri);
        }
    },
};
jordanb2b.Price = {
    Format: function (priceIn, prefix, suffix, centsSeparator, thousandsSeparator, centsLimit) {
        if (!centsSeparator)
            centsSeparator = jordanb2b.Settings.Currency.DecimalSeparator;
        if (!thousandsSeparator)
            thousandsSeparator = jordanb2b.Settings.Currency.ThousandSeparator;
        if (!centsLimit)
            centsLimit = 2;
        if (jordanb2b.Settings.Currency.SignPosition == 1) {
            suffix = ' ' + jordanb2b.Settings.Currency.Sign;
            prefix = '';
        } else {
            prefix = jordanb2b.Settings.Currency.Sign + ' ';
            suffix = '';
        }

        var price = '0';

        if (priceIn !== 0) {
            var pow = Math.pow(10, centsLimit);
            price = (Math.round(priceIn * pow) / pow) + "";

            if (price === '0') {
                var centsLimitMore = centsLimit;
                while (price === '0') {
                    pow = Math.pow(10, centsLimitMore++);
                    price = (Math.round(priceIn * pow) / pow) + "";
                }
                centsLimit = centsLimitMore - 1;
            }
        }

        price = price.replace(',', '.');
        //format to price basic
        var splitter = price.split('.');
        var splitter2 = price.split(',');
        var format;
        var zeroFiller = '00';

        format = splitter[0];
        if (splitter[0] == splitter2[0]) {
            format += zeroFiller;
        } else if (splitter[1] != undefined && splitter2[1] == undefined) {
            format += splitter[1];
            if (splitter[1].length == 1) {
                format += '0';
            }
        } else if (splitter2[1] != undefined && splitter[1] == undefined) {
            format += splitter2[1];
            if (splitter2[1].length == 1) {
                format += '0';
            }
        }

        // cents
        var centsVal = format.substr(format.length - centsLimit, centsLimit);
        var integerVal = format.substr(0, format.length - centsLimit);
        format = integerVal + centsSeparator + centsVal;

        // thousands
        var thousandsFormatted = '';
        var thousandsCount = 0;
        if (thousandsSeparator) {
            for (var j = integerVal.length; j > 0; j--) {
                var chars = integerVal.substr(j - 1, 1);
                thousandsCount++;
                if (thousandsCount % 3 == 0)
                    chars = thousandsSeparator + chars;
                thousandsFormatted = chars + thousandsFormatted;
            }
            if (thousandsFormatted.substr(0, 1) == thousandsSeparator)
                thousandsFormatted = thousandsFormatted.substring(1, thousandsFormatted.length);
            format = thousandsFormatted + centsSeparator + centsVal;
        }

        return prefix + format + suffix;
    },
    FormatLimit: function (price, centsLimit) {
        return jordanb2b.Price.Format(price, null, null, null, null, centsLimit);
    }
};
(function ($) {
    $.loadSelectableProductsModalContainer = function () {
        var selectableProductsModalContainer = $('div.selectableProductsModalContainer');
        if (selectableProductsModalContainer.length === 1) {
            $.loadModalContent(null, selectableProductsModalContainer.html(), 0, 'lg');
            var selectableProductsDiscountForm = $('form.selectable-products-discount-form');

            $("input.selectable-product", selectableProductsDiscountForm).on('change', function(event) {
                event.preventDefault();
                var productsSelectionLogicType = selectableProductsDiscountForm.data('productsSelectionLogicType');
                var maxAmountProductsAllowed = selectableProductsDiscountForm.data('maxAmountProductsAllowed');
                var amountSelectedProducts = $('input.selectable-product:checked', selectableProductsDiscountForm).length;
                var addSelectedProductsToCartButton = $('div.modal-footer button.addSelectedProductsToCart');

                if ((productsSelectionLogicType === 'ANDL' && amountSelectedProducts > maxAmountProductsAllowed)
                    ||
                    (productsSelectionLogicType === 'ORL' && amountSelectedProducts === 2)
                ) {
                    $(this).prop('checked', false);
                }

                if ($('input.selectable-product:checked', selectableProductsDiscountForm).length > 0) {
                    addSelectedProductsToCartButton.removeClass('disabled');
                } else {
                    addSelectedProductsToCartButton.addClass('disabled');
                }

            });

            $('div.modal-footer button.addSelectedProductsToCart').on('click', function(event) {
                var form = $('form.selectable-products-discount-form');
                var url = form.data('action');

                $.ajax({
                    url: url,
                    type: 'post',
                    data: form.serialize(),
                    dataType: 'json',
                    complete: function () {
                        $('#modal-container').modal('hide');
                        window.location.reload();
                    }
                });
            });
        }
    };
}(jQuery));
$(document).ready(function () {
    //document ready
}).on('click', 'a.add-product-to-bookmark', function (event) {
    event.preventDefault();
    var me = $(this);
    var productId = me.data('productId');
    var productList = me.data('product-list');
    if (! productId && ! productList) {
        return false;
    }

    var dataLayer = me.data('data-layer');

    if (dataLayer) {
        // handle product detail page
        var form = $('form.form-calculation-' + productId + ':visible');
        if (form.length > 0) {
             try {
                dataLayer['items'][0]['quantity_calculated'] = parseFloat(form.first().find('input#quantity').val().replace(',','.'));
                dataLayer['items'][0]['quantity'] = parseFloat(form.first().find('input#amounts0').val());
                dataLayer['items'][0]['price'] = parseFloat(form.first().find('.price-total').html().replace(',', '.').match(/(^\S+)/));
            } catch (e) {
                console.error(e);
            }
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'items': undefined});
        window.dataLayer.push(dataLayer);
    }

    if (false === me.checkLoginModal(event)) {
        return false;
    }
    if (productList === undefined) {
        productList = null;
    }

    var form = $('.form-calculation.form-calculation-' + productId);
    var data;

    if($(form[0]).length === 0) {
        data = $('.form-horizontal.calculator.calculator-' + me.data('cart-id'))
            .find('input')
            .serialize() + '&' + 'productId=' + productId;
    } else {
        data = $(form[0]).serialize() + '&' + 'productId=' + productId + '&' + 'productList=' + productList;
    }
    $.ajax({
        'url': '/dejordanbookmark/getListsTemplate',
        'type': 'POST',
        'data': data,
        'async': true,
        'complete': function (data, textStatus) {
            if (textStatus === "success") {
                $.loadModalContent(event, data.responseText, 0, 'md');
            }
        }
    });
}).on('click', 'a.add-product-to-favorite', function (event) {
    event.preventDefault();
    var me = $(this),
        productId = me.data('productId');
    if (!productId) {
        return false;
    }

    var dataLayer = me.data('data-layer');
    if (dataLayer) {
        // handle product detail page
        var form = $('form.form-calculation-' + productId + ':visible');
        if (form.length > 0) {
             try {
                dataLayer['items'][0]['quantity_calculated'] = parseFloat(form.first().find('input#quantity').val().replace(',','.'));
                dataLayer['items'][0]['quantity'] = parseFloat(form.first().find('input#amounts0').val());
                dataLayer['items'][0]['price'] = parseFloat(form.first().find('.price-total').html().replace(',', '.').match(/(^\S+)/));
             } catch (e) {
                console.error(e);
             }
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'items': undefined});
        window.dataLayer.push(dataLayer);
    }

    if (false === me.checkLoginModal(event)) {
        return false;
    }
    $.post('/dejordanbookmark/addToFavorite', {productId: productId}, function (response) {
        //show modal
        $.loadModalContent(event, response, jordanb2b.Settings.Growl.life, 'md');
        // switch buttons
        $('.add-product-to-favorite[data-product-id="' + productId + '"]').addClass('hidden');
        $('.product-is-favorite[data-product-id="' + productId + '"]').removeClass('hidden');
        $.favoriteLayer(event);
    });
}).on('submit', 'form.add-to-bookmark-list', function (event) {
    event.preventDefault();
    var me = $(this);
    if (false === me.checkLoginModal(event)) {
        return false;
    }

    var dataLayer = me.data('data-layer');
    if (dataLayer) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataLayer);
    }

    $.post(me.attr("action"), me.serialize(), function (response) {
        //show modal
        $.loadModalContent(event, response, 0, 'md');
        $.bookmarkLayer(event);
    });
}).on('submit', 'form.form-add-to-bookmark', function (event) {
    event.preventDefault();
    var me = $(this);
    $.post(me.attr('action'), me.serialize(), function (response) {
        //show modal
        $.loadModalContent(event, response, 0, 'md');
        $.bookmarkLayer(event);
    });
}).on('click', '.remove-product-bookmark', function (event) {
    event.preventDefault();
    var me = $(this);
    $.ajax({
        'url': $(this).attr('href'),
        'type': 'GET',
        'async': true,
        complete: function (data, textStatus) {
            if (data.responseJSON.success) {
                var target = me.data('target'),
                    parent = me.data('parent'),
                    containerTarget = $('#' + target),
                    containerParent = $('#' + parent);
                if (containerTarget.length > 0) {
                    var messageContainer = $("<div>").html(data.responseJSON.success).addClass('alert alert-success').attr('id', 'message-' + target);
                    messageContainer.insertAfter(containerTarget);
                    containerTarget.fadeOut('slow', function () {
                        containerTarget.remove();
                        messageContainer.fadeIn('slow');
                        var totalList = $('.orderItem', containerParent).length;
                        if (totalList <= 0 && containerParent.length > 0) {
                            $('.hide-empty-list', containerParent).addClass('hidden');
                            $('.show-empty-list', containerParent).removeClass('hidden');
                        }
                        setTimeout(function () {
                            messageContainer.fadeOut('slow');
                        }, jordanb2b.Settings.Growl.life);
                    });
                }
            }
            return true;
        }
    });
}).on('click', '.add-cart-to-bookmark', function (event) {

    event.preventDefault();
    let me = $(this);

    if (false === me.checkLoginModal(event)) {
        return false;
    }

    $.ajax({
        'url': '/dejordanbookmark/getListsTemplate',
        'type': 'POST',
        'data': {cart: true},
        'async': true,
        'complete': function (data, textStatus) {
            if (textStatus === "success") {
                $.loadModalContent(event, data.responseText, 0, 'md');
            }
        }
    });
});
$(document).ready(function () {
    //document ready
}).on('click', '.requirement-calculator .image-chooser', function (event) {
    event.preventDefault();

    var me = $(this),
        value = me.data('value'),
        target = me.data('target');

    if ($(target).length > 0) {
        //set value
        $(target).val(value);
    }

    //activate
    $('.requirement-calculator .image-chooser').not(me).removeClass('active');
    me.addClass('active');

    return false;
}).on('terrace-calc-finish', function(event) {
    var height = TerraceCalculator.heightMin;
    if (event.detail !== false && event.detail.result !== false) {
        height = event.detail.mode === 'base' ? TerraceCalculator.heightBase : TerraceCalculator.heightDefault;
        // log usage
        if (event.detail.result !== false) {
            $.ajax({
                url: '/dejordan/ajax/terraceCalc',
                data: {
                    l: event.detail.newValues[0],
                    w: event.detail.newValues[1],
                    m: event.detail.mode,
                    p: TerraceCalculator.selectedProductExtId
                },
                type: 'POST',
                dataType: "json",
                async: true
            });
        }
    }
    // set height of iframe
    $(TerraceCalculator.iframe).css('height', height + 'px');
});

function terraceCalculatorAddToBasket(el, event) {
    var trCal = new TerraceCalculator();
    trCal.addProducts(el, event);
    trCal.addToBasket();
    return false;
}

function terraceCalculatorAddToFavorites(el, event) {
    var trCal = new TerraceCalculator();
    trCal.addProducts(el, event);
    trCal.addToFavorites();
    return false;
}

function terraceCalculatorAddToBookmarks(el, event) {
    var trCal = new TerraceCalculator();
    trCal.addProducts(el, event);
    trCal.addToBookmarks();
    return false;
}

function TerraceCalculator() {
    var products = [];
    var triggerEl;
    var event;

    this.setTriggerEl = function (el) {
        triggerEl = el;
    };

    this.setEvent = function (ev) {
        event = ev;
    };

    this.addProducts = function(el, ev) {
        if (el.tagName === 'A') {
            var $tr = $(el).closest('tr');
            this.addProductFromTr($tr);
        } else {
            var me = this;
            $('#resultTable > tbody > tr', TerraceCalculator.iframe.contentDocument).each(function (i, e) {
                me.addProductFromTr(e);
            });
        }
        this.setTriggerEl(el);
        this.setEvent(ev);
    };

    this.addProductFromTr = function(tr) {
        var productExtId = $(tr).data('item');
        var amount = parseInt($(tr).find('td:eq(2) > div').text());
        if (TerraceCalculator.selectedProductExtId.indexOf(productExtId) === 0) {
            productExtId = TerraceCalculator.selectedProductExtId;
        }
        products.push([productExtId, amount]);
    };

    this.addToBasket = function() {
        if (products.length > 0) {
            $(triggerEl).attr('disabled', 'disabled');
            $.ajax({
                url: '/dejordan/ajax/addtocart',
                data: JSON.stringify(products),
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                async: true,
                success: function (response) {
                    if (response.success) {
                        $.cartLayer(event);
                    } else {
                        alert('Das Produkt konnte nicht in den WK gelegt werden');
                    }
                }
            }).always(function () {
                //enable buttons
                $(triggerEl).removeAttr('disabled');
            });
        }
    };

    this.addToBookmarks = function() {
        if (false === $(triggerEl).checkLoginModal(event)) {
            return false;
        }
        var data = {};
        if (products.length == 1) {
            data.vendorProductId = products[0][0];
        } else {
            data.productList = '';
            for (var i = 0; i < products.length; i++) {
                data.productList += encodeURIComponent(products[i][0] + ':' + products[i][1]) + ',';
            }
            data.productList = data.productList.substr(0, data.productList.length - 1);
        }

        $.ajax({
            'url': '/dejordanbookmark/getListsTemplate',
            'type': 'GET',
            'data': data,
            'async': true,
            'complete': function (data, textStatus) {
                //show modal
                if (textStatus === "success") {
                    //show modal
                    $.loadModalContent(event, data.responseText, 0, 'md');
                }
            }
        });
    };

    this.addToFavorites = function() {
        if (false === $(triggerEl).checkLoginModal(event)) {
            return false;
        }
        var vendorProductId;
        for (var i = 0; i < products.length; i++) {
            vendorProductId = products[i][0];
            break;
        }

        $.post('/dejordanbookmark/addToFavorite', {vendorProductId: vendorProductId}, function (response) {
            //show modal
            $.loadModalContent(event, response, jordanb2b.Settings.Growl.life, 'md');
            // switch buttons
            //$('.add-product-to-favorite[data-product-id="' + productId + '"]').addClass('hidden');
            //$('.product-is-favorite[data-product-id="' + productId + '"]').removeClass('hidden');
            $.favoriteLayer(event);
        });

    };
};

TerraceCalculator.iframe = $('#terrace-calculator iframe')[0];
TerraceCalculator.selectedProductExtId = $('td.vendorProductId').text();
TerraceCalculator.heightMin = 315;
TerraceCalculator.heightDefault = 815;
TerraceCalculator.heightBase = 1070;

TerraceCalculator.factory = function () {
    return new this();
};

TerraceCalculator.hasCalculation = function () {
    return parseInt($(this.iframe).css('height')) > this.heightMin;
};

$(window).on('message', function (e) {
    'use strict';
    if (e.originalEvent.data.hasOwnProperty('frameHeight')) {
        var h = e.originalEvent.data.frameHeight + 30;
        $('iframe#calculator-frame').height(h + 'px');
    }
});

window.addEventListener('scroll', function (event) {
    if (window.location.pathname.startsWith('/pvz-konfigurator')) {
        var calculatorFrame = document.querySelector('#calculator-frame'),
            topOffset = calculatorFrame.getBoundingClientRect().top + window.scrollY,
            currentScroll = document.scrollingElement.scrollTop;
        calculatorFrame.contentWindow.postMessage(topOffset + ':' + currentScroll, '*');
    }
});
$(document).ready(function () {
    $.showSpinner = function (enable) {
        if (jordanb2b.Settings.ShowSpinner) {
            var pContent = $('#product-content'),
                requestLoader = $('.requestloader');
            if (enable === true) {
                pContent.addClass('ajaxloader');
                requestLoader.show().addClass('active');
            } else {
                pContent.removeClass('ajaxloader');
                requestLoader.removeClass('active');
            }
        }
    };
    $('#products div.caption', '#startProducts').matchHeight({
        byRow: true
    });

    // close accordion in mobile view
    if ($(window).width() <= 1024) {
        $('#collapse-filter').collapse('hide');
        $("#filter-settings").appendAttributeButtons('show');
    } else {
        if (!$('#collapse-filter').hasClass('in')) {
            $("#filter-settings").appendAttributeButtons('show');
        }
    }
}).on('change', '.container-category-overview select.change-sort', function (event) {
    var sort = $(this).val(),
        map = $(this).data('map') ? $(this).data('map') : '/dejordan/productFilter';
    $.showSpinner(true);
    $.ajax({
        url: map + '/setSort/' + sort,
        data: {content: 1},
        dataType: 'json',
        type: 'get',
        success: function (response) {
            $.showSpinner(false);
            if (response.success) {
                $('#content-container').updateProductFilterContent(response.content, response.onlyproducts);
            }
        }
    });
}).on('change', '.container-category-overview select.change-items-per-page', function (event) {
    var items = $(this).val(),
        map = $(this).data('map') ? $(this).data('map') : '/dejordan/productFilter';
    $.showSpinner(true);
    $.ajax({
        url: map + '/setItemsPerPage/' + items,
        data: {content: 1},
        dataType: 'json',
        type: 'get',
        success: function (response) {
            $.showSpinner(false);
            if (response.success) {
                $('#content-container').updateProductFilterContent(response.content, response.onlyproducts);
            }
        }
    });
}).on('click', '.container-category-overview .pagination a,.container-category-overview .nextPage', function (event) {
    var me = $(this);
    if (me.hasClass('noAjax')) {
        return;
    }
    event.preventDefault();
    var page = me.data('page'),
        map = me.data('map') ? me.data('map') : '/dejordan/productFilter';
    $.showSpinner(true);
    $.ajax({
        url: map + '/setPage/' + page,
        data: {content: 1},
        dataType: 'json',
        type: 'get',
        success: function (response) {
            $.showSpinner(false);
            if (response.success) {
                $('#content-container').updateProductFilterContent(response.content, response.onlyproducts);
                $("html, body").animate({scrollTop: 0}, "slow");
            }
        }
    });
}).on('change', 'select.attribute-filter', function (event) {
    var me = $(this);
    if ($("option:selected", me).attr('value')) {
        me.addClass('selected');
    } else {
        me.removeClass('selected');
    }
    // attributeFilter
    $.showSpinner(true);
    window.jordanb2b.util.getSelectedFilterElements().fetchAttributes(function () {
        $.showSpinner(false);
    });
}).on('change', '.container-category-overview .checkbox input[name="group-variants"]', function (event) {
    var me = $(this),
        map = me.data('map') ? me.data('map') : '/dejordan/productFilter',
        group = me.is(':checked') ? 1 : 0;
    $.showSpinner(true);
    $.ajax({
        url: map + '/group-variants/' + group,
        data: {content: 1},
        dataType: 'json',
        type: 'get',
        success: function (response) {
            $.showSpinner(false);
            if (response.success) {
                $('#content-container').updateProductFilterContent(response.content, response.onlyproducts);
            }
        }
    });
}).on('change', '.container-category-overview .checkbox input[name="stock-items"]', function (event) {
    var me = $(this),
        map = me.data('map') ? me.data('map') : '/dejordan/productFilter',
        stockItems = me.is(':checked') ? 1 : 0;
    $.showSpinner(true);
    $.ajax({
        url: map + '/stock-items/' + stockItems,
        data: {content: 1},
        dataType: 'json',
        type: 'get',
        success: function (response) {
            $.showSpinner(false);
            if (response.success) {
                $('#content-container').updateProductFilterContent(response.content, response.onlyproducts);
            }
        }
    });
}).on('click', '.container-category-overview .view-as-list,.container-category-overview .view-as-gallery', function (event) {
    event.preventDefault();
    var me = $(this),
        type = me.data('display'),
        map = me.data('map') ? me.data('map') : '/dejordan/productFilter',
        list = $("#products-list"),
        gallery = $("#products");

    if (me.hasClass('view-as-list') && list.hasClass('show')) {
        //nothing to do
        return false;
    } else if (me.hasClass('view-as-gallery') && gallery.hasClass('show')) {
        //nothing to do
        return false;
    }

    if (type) {
        $.ajax({
            url: map + '/setDisplay/' + type,
            dataType: 'json',
            type: 'get'
        });
    }
    gallery.toggleClass("show").toggleClass("hide");
    list.toggleClass("hide").toggleClass("show");
    $('#products div.caption', '#startProducts').matchHeight({
        byRow: true
    });
    $('.panel-product').matchHeight();
}).on('slideStop', '.form-slider', function () {
    var me = $(this);
    if (me.val() != me.attr('data-old-value')) {
        me.addClass('selected');
        $.showSpinner(true);
        window.jordanb2b.util.getSelectedFilterElements().fetchAttributes(function () {
            $.showSpinner(false);
        });
    }
}).on('click', '.filter-button', function () {
    var me = $(this),
        attributeId = me.data('remove');
    $('#' + attributeId + ',#' + attributeId + '-mobile').removeClass('selected');
    // attributeFilter
    window.jordanb2b.util.getSelectedFilterElements(false).fetchAttributes(function () {
        me.fadeOut("slow");
    });
}).on('click', 'button.filter-reset', function () {
    // attributeFilter
    var selected = window.jordanb2b.util.getSelectedFilterElements(false);
    if (selected.length > 0) {
        selected.removeClass('selected').fetchAttributes();
    }
    return false;
});

$(document).ready(function () {
    //item comment
    $('.product-comment form .counter').each(function () {
        var identification = $(this).data('identification'),
            textarea = $('textarea#comment-' + identification),
            max = textarea.attr('maxlength'),
            current = textarea.val().length;
        //var text = $(this).html().replace('%d', '<span class="text-success">' + max + '</span>');
        var remaining = max;
        if (current > 0) {
            remaining = max - current;
        }
        var text = $(this).html().replace('%d', '<span>' + remaining + '</span>');
        $(this).html(text);
    });

    //disable input fields
    var overviewInputFields = $('div.cart-update.cart-overview');
    if (overviewInputFields.length > 0) {
        overviewInputFields.find('input[type="text"]').each(function () {
            $(this).attr('readonly', 'readonly');
        });
    }

    $.loadSelectableProductsModalContainer();
    $.checkCartModifications();

}).on('keyup', 'div.product-comment form textarea', function (event) {
    event.preventDefault();
    var identification = $(this).data('identification'),
        maxLength = $(this).attr('maxlength'),
        length = $(this).val().length,
        success = $('#form-success-' + identification);

    if (success.length > 0) {
        success.addClass('hidden');
    }
    if (length > 1) {
        $(this).data('changed', 1);
    } else {
        $(this).data('changed', 0);
    }
    var remaining = maxLength - length;
    var counter = $('#counter-' + identification + ', #counter-' + identification + '-mobile');
    /*if (remaining > 0) {
     counter.find('span:first').addClass('text-success').removeClass('text-danger');
     } else {
     counter.find('span:first').addClass('text-danger').removeClass('text-success');
     }*/
    counter.find('span:first').text(remaining);
    return false;
}).on('click', 'div.product-comment .save-comment', function (event) {
    var form = $($(this).data('comment-form'));
    if (form.length > 0) {
        $.ajax({
            url: form.attr('action'),
            'type': 'POST',
            'data': form.serialize(),
            'async': true,
            success: function (response) {
                if ('true' === response) {
                    form.find('textarea').data('changed', 0);
                    form.find('.alert-success').removeClass('hidden');
                }
            }
        });
    }
    return false;
}).on('click', '.check-comments', function (event) {
    var valid = true;
    $('div.product-comment form textarea').each(function () {
        if (1 === $(this).data('changed')) {
            $.loadModalContent(
                event,
                $('div#message-unsaved-comment').html(),
                0,
                'md'
            );
            valid = false;
            event.preventDefault();
            return false;
        }
    });
    return valid;
}).on('change', '#check-shipping', function (event) {
    if ($(this).is(":checked")) {
        $('input#shippingAddressSelected').val(1);
        $('input,select', 'div.deliver-address').removeAttr('disabled');

        //'checked' event code
        $(".deliver-address").fadeIn("slow", function () {
            // Animation complete
        });
    } else {
        $('input#shippingAddressSelected').val(0);
        $('input,select', 'div.deliver-address').attr('disabled', 'disabled');

        $(".deliver-address").fadeOut("slow", function () {
            // Animation complete
        });
    }
}).on('change', '.caroids input:radio[name ="caroids"]', function (event) {
    var caroidsValue = $(".caroids input:radio[name ='caroids']:checked").val();
    if (caroidsValue.length > 0 && caroidsValue == 'noFinishOrderWithCaroIds') {
        $('#jorCheckoutOverviewBtn').text($('#jorCheckoutOverviewBtnContent2').val());
    } else {
        $('#jorCheckoutOverviewBtn').text($('#jorCheckoutOverviewBtnContent1').val());
    }
}).on('click', '#jorCheckoutOverviewBtn', function (event) {
    event.preventDefault();
    var caroidsInput = $('.caroids input:radio[name ="caroids"]:checked');
    if (caroidsInput.length > 0 && caroidsInput.val() === 'noFinishOrderWithCaroIds') {
        var cart = $('#caroCartContent').val();
        var hookUrl = $('#caroHookUrl').val();
        if (cart.length > 0 && hookUrl.length > 0) {
            $.ajax({
                url: hookUrl,
                data: {
                    'warenkorb': cart
                },
                type: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                contentType: 'application/x-www-form-urlencoded; charset=utf-8',
                dataType: 'xml',
                async: true,
                success: function (response) {
                    //console.log(response);
                }
            });
        }
        return false;
    }
    $('body').blockingOverlay();
    $(this).attr('disabled', 'disabled');
    $(this).spinnerButton('start');
    $(this).closest('form').submit();
    return true;
}).on('change', '.radio-versand', function (event) {
    if (!expressShippingId || parseInt(expressShippingId) === 0) {
        return;
    }
    $.checkDeliverydate($(this).is(':checked') && parseInt($(this).val()) === parseInt(expressShippingId));
});
$(document).ready(function () {

}).on('click', '#registerexistingcustomer', function () {
    $(this).addClass('active');
    $('#registernewcustomer').removeClass('active');
    $('.register-new-user').fadeOut();
    $('.register-existing-user').fadeIn();
}).on('click', '#registernewcustomer', function () {
    $(this).addClass('active');
    $('#registerexistingcustomer').removeClass('active');
    $('.register-new-user').fadeIn();
    $('.register-existing-user').fadeOut();
}).on('change', '.container-order-history select.change-items-per-page', function (event) {
    event.preventDefault();
    $.get('/dejordanorder', {itemsPerPage: $(this).val(), page: 1}, function (response) {
        $('div.content-container').html(response);
    });
}).on('click', '.container-order-history .pagination a', function (event) {
    event.preventDefault();
    var page = $(this).attr('data-page');
    var itemsPerPage = $('.container-order-history select.change-items-per-page').val();
    $.get('/dejordanorder', {itemsPerPage: itemsPerPage, page: page}, function (response) {
        $('div.content-container').html(response);
    });
});
(function ($) {
    $.filterDocumentTable = function () {
        var activeSelection = [];
        $('button', '#documentselector').each(function (i, elem) {
            if ($(this).hasClass('active')) {
                activeSelection.push($(this).data('key'));
            }
        });
        $.fn.dataTable.tables({
            visible: true,
            api: true
        }).columns('.searchable')
            .search(activeSelection.join('|'), true)
            .draw();
    };
}(jQuery));

$(document).ready(function () {
    //on document ready
}).on('click', '#documentselector button', function (event) {
    event.preventDefault();
    if ($(this).data('href')) {
        return false;
    }

    var target = $(this).data('target'),
        currentTarget = $('.document-toggle-container:visible').attr('id');
    if (target) {
        $('.document-toggle-container').addClass('hidden');
        $('#' + target).removeClass('hidden');
    }

    if (target === 'openitems-container') {
        //remove selected document buttons
        $('#documentselector button.active').toggleFilterButton();
        //hide sections
        $('.document-toggle-openitems-container').addClass('hidden');
    } else {
        //remove open items buttons
        $('#documentselector button.active[data-target="openitems-container"]').toggleFilterButton();
        //dhow sections
        $('.document-toggle-openitems-container').removeClass('hidden');
    }
    if (currentTarget != target) {
        $.fn.dataTable.tables({visible: true, api: true}).columns.adjust();
    }
    $(this).toggleFilterButton();
    $.filterDocumentTable();
}).on('click', '.container-documents button.download-all', function (event) {
    event.preventDefault();
    $('.container-documents .documents-error').addClass('hidden');
    $.downloadFile('/dejordan/documentsDownload');
    return true;
}).on('click', '.container-documents button.download-selected', function (event) {
    event.preventDefault();
    var docIds = {};
    $('.document-items input[type="checkbox"]:checked').each(function (index, elem) {
        docIds[index] = $(elem).val();
    });
    if (Object.keys(docIds).length === 0) {
        $('.container-documents .documents-error').removeClass('hidden');
        return false;
    } else {
        $('.container-documents .documents-error').addClass('hidden');
        $.downloadFile('/dejordan/documentsDownload', {'getDocs': JSON.stringify(docIds)});
    }
    return true;
}).on('click', '.container-documents a.document-show', function (event) {
    var close = '<div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>';
    var iframeContainer = '<div class="iframe-container">' + close + '<iframe class="embed-responsive-item" src="' + $(this).attr('href') + '" frameborder="0" style="width:100%;height:75vh;display:block;"/></div>';
    //evt, content, timeout, size, withTemplate, title, callback
    $.loadModalContent(event, $(iframeContainer).html());

    //$.downloadFile($(this).attr('href'));
    return false;
}).on('click', '.container-documents a.document-download', function (event) {
    event.preventDefault();
    $.downloadFile($(this).attr('href'));
    return false;
}).on('click', '.container-price-export #categoryselector button', function (event) {
    event.preventDefault();
    $(this).toggleFilterButton();
    return false;
}).on('click', '.container-price-export #downloadselector button', function (event) {
    event.preventDefault();
    var type = $(this).data('type');
    if (!type) {
        return false;
    }

    var categories = [];
    $('button', '#categoryselector').each(function (i, elem) {
        if ($(this).hasClass('active')) {
            categories.push($(this).data('key'));
        }
    });

    if (categories.length > 0) {
        $('.container-price-export .documents-error').addClass('hidden');
        if ($(this).hasClass('btn-email')) {
            //show modal
            $('.container-price-export #email-modal').modal('show')
                .on('shown.bs.modal', function (e) {
                    var modal = $(this);
                    jordanb2b.util.updateFormStyles(modal);
                    modal.find('form').unbind('submit')
                        .on('submit', function (e) {
                            e.preventDefault();

                            modal.find('.alert-danger').addClass('hidden');

                            var target = $(event.currentTarget);
                            if (target.length > 0) {
                                target.spinnerButton('start');
                            }

                            var email = $(this).find('input').first().val();
                            $.post($(this).attr('action'), {
                                email: email,
                                type: type,
                                categories: categories
                            }, function (response) {
                                if (response.success) {
                                    modal.modal('hide');
                                } else {
                                    modal.find('.alert-danger').removeClass('hidden');
                                }
                                if (target.length > 0) {
                                    target.spinnerButton('stop');
                                }
                            });
                            return false;
                        });
                });
        } else {
            //download file
            $.downloadFile('/dejordan/priceexport', {type: type, categories: categories}, '#download-prepare-modal');
        }
    } else {
        $('.container-price-export .documents-error').removeClass('hidden');
    }

    return true;
}).on('click', 'table.document-items tr input', function (event) {
    var checkbox = $(this),
        row = $(this).closest('tr');
    //var checkbox = $(this).find('input');
    checkbox.attr('checked', !checkbox.attr('checked'));
    if (checkbox.is(':checked')) {
        row.addClass('marked');
    } else {
        row.removeClass('marked');
    }
}).on('click', 'a.documents-email', function (event) {
    event.preventDefault();
    var me = $(this);
    if (false === me.checkLoginModal(event)) {
        return false;
    }
    $.loadTemplateForModal(event, me.attr('href'));
    return true;
});
(function ($) {
    $.resetDownloadsTable = function () {
        if ($.fn.DataTable.isDataTable('.container-download #downloads table')) {
            $('.container-download #downloads table').DataTable().clear();
            $('.container-download #downloads table').DataTable().destroy();
            $('.pagination-container, .length-container, .searchable-container').empty();
        }
    };

    $.initDownloadsTable = function () {
        $.resetDownloadsTable();
        $('.container-download #downloads table').on('init.dt', function () {
            $('.dataTables_paginate').appendTo('.pagination-container');
            $('.dataTables_length').appendTo('.length-container');
            $('.dataTables_filter').appendTo('.searchable-container');
        }).DataTable({
            'info': false,
            language: {
                'url': '/static/de.jordan.b2b.theme/assets/js/libs/datatables/i18n/de-de.json'
            },
            initComplete: function () {
                // Apply default/pre-selected filters after table has been initialised
                $.filterDownloadsTable();
            },
            drawCallback: function (settings) {
                //pagination
                var pagination1 = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate'),
                    pagination2 = $('.pagination-container').find('.dataTables_paginate'),
                    showPagination = this.api().page.info().pages > 1;
                pagination1.toggle(showPagination);
                pagination2.toggle(showPagination);
                //lenght change
                var length1 = $(this).closest('.dataTables_wrapper').find('.dataTables_length'),
                    length2 = $('.length-container').find('.dataTables_length'),
                    showLength = (this.api().page.info().pages > 1 || this.api().page.info().recordsTotal > 10);
                length1.toggle(showLength);
                length2.toggle(showLength);

                var maxResults = $('#max-results-limit-warning-container').data('max-results');
                $('#max-results-limit-warning-container').toggle(this.api().rows().count() > maxResults);

                //download button
                var checkboxes = $(this).closest('.dataTables_wrapper').find('input[type="checkbox"]');
                if (!checkboxes.length) {
                    $('button#download-collection').hide();
                } else {
                    $('button#download-collection').show();
                }
            }
        });
    };

    $.filterDownloadsTable = function () {
        var activeSelection = [];
        $('button', '#typeSelector').each(function (i, elem) {
            if ($(this).hasClass('active')) {
                activeSelection.push($(this).data('type'));
            }
        });
        //generate random string to search -> workaround for display no results when no type is chosen
        /*if (activeSelection.length <= 0) {
            activeSelection.push(Math.random().toString(36).substring(7));
        }*/
        var table = $.fn.dataTable.tables({
            visible: true,
            api: true
        });

        if (activeSelection.length > 0) {
            table.columns('.searchable').search(activeSelection.join('|'), true);
        } else {
            table.columns('.searchable').search('');
        }
        table.draw();
    };

    $.ajaxCategoriesCall = function (url, handleCategories, handleProducts) {
        $.showDownloadLoadingIndicator();

        $.ajax({
            url: url,
            type: 'post',
            data: $('form#params-form').serialize(),
            dataType: 'json',
            success: function (data) {
                $.handleResults(data, handleCategories, handleProducts);
            }
        });
    };

    $.showDownloadLoadingIndicator = function () {
        var spinner = new Spinner({ position: 'absolute', left: '20px', scale: 0.5, color: '#ffffff', }).spin();
        $('.container-download #loading .spinner-container').html(spinner.el);
        $('.container-download #loading').show();
    };

    $.hideDownloadLoadingIndicator = function () {
        $('.container-download #loading').hide();
    };

    $.handleResults = function (data, handleCategories, handleProducts) {
        var tplCategory = $.templates('#downloads-category'),
            tplProducts = $.templates('#downloads-product'),
            tplTypes = $.templates('#downloads-type-selector'),
            tplDocuments = $.templates('#downloads-document'),
            tplFallback = $.templates('#downloads-fallback');

        if (typeof handleCategories === 'undefined') {
            handleCategories = true;
        }
        if (typeof handleProducts === 'undefined') {
            handleProducts = true;
        }

        try {
            //categories section
            if (handleCategories) {
                if (data.categories && data.categories.length > 0) {
                    $('.container-download #subcategory .list-group').html(tplCategory.render(data.categories));
                } else {
                    $('.container-download #subcategory .list-group').html('');
                }
            }

            //products
            if (handleProducts) {
                if (data.products && data.products.length > 0) {
                    $('.notice,.message', '.container-download #productlist').hide();
                    $('.container-download #productlist .list-group').html(tplProducts.render(data.products)).show();
                } else if (data.message) {
                    $('.container-download #productlist .list-group').html('').hide();
                    $('.container-download #products .notice').hide();
                    $('.container-download #productlist .message').html(data.message).show();
                } else {
                    $('.container-download #products .list-group').hide();
                    $('.container-download #products .notice').show();
                    $('.container-download #products .message').hide();
                }
            }

            var html = '';

            //documents
            if (data.documents && data.documents.length > 0) {
                //reset download table
                $.resetDownloadsTable();

                //document types
                if (data.types && data.types.length > 1) {
                    html = html + tplTypes.render(data.types);
                }
                if (html.length > 0) {
                    $('.container-download #downloads #typeSelector').html(html).show();
                } else {
                    $('.container-download #downloads #typeSelector').hide();
                }
                //documents
                $('.container-download #downloads table tbody').html(tplDocuments.render(data.documents));
            } else {
                //languages select if available
                if (html.length > 0) {
                    $('.container-download #downloads #typeSelector').html(html).show();
                }
                //fallback message
                $('.container-download #downloads table tbody').html(tplFallback.render());
            }

            //downloads data table
            $.initDownloadsTable();
            //init select2
            window.jordanb2b.util.initSelect2();

            //show downloads
            $('#downloads', '.container-download').show();
            //show legend
            $('#legend', '.container-download').show();

            if (handleCategories) {
                //show/hide product/categories selection
                if (data.categories && data.categories.length > 0) {
                    $('#sub, #products', '.container-download').show();
                } else {
                    $('#sub, #products', '.container-download').hide();
                }
            }
        } catch (e) {
            console.error(e);
        }

        var aTag = $('#main', '.container-download'),
            stickyHeader = $('.basic-sticky-example'),
            mainDownload = $('.main-categories'),
            additional = stickyHeader.height() - mainDownload.height();

        if (!stickyHeader.hasClass('stuck')) {
            additional = 140;
        }

        $.hideDownloadLoadingIndicator();
        $('html,body').animate({scrollTop: aTag.offset().top - additional}, 'slow');

    };

}(jQuery));

$(document).ready(function () {
    //on document ready
    if (typeof initialDocuments !== 'undefined' && window.getDownloads && initialDocuments.length > 0) {
        getDownloads(initialDocuments);
    }
}).on('click', '.container-download .download-category', function (event) {
    event.preventDefault();
    //reset active product
    $('form#params-form input[name="product"]').val('');
    if ($(this).data('category')) {
        $('form#params-form input[name="category"]').val($(this).data('category'));
    }
    $.ajaxCategoriesCall($(this).attr('href'));
}).on('click', '.container-download .item-sub', function (event) {
    event.preventDefault();
    var me = $(this),
        url = '/dejordandownloads/products/' + me.data('category');
    if (me.data('category')) {
        $('form#params-form input[name="category"]').val(me.data('category'));
    }
    //reset active product
    $('form#params-form input[name="product"]').val('');

    $('#sub .active').removeClass('active');
    me.addClass('active');

    $.showDownloadLoadingIndicator();

    $.ajax({
        url: url,
        type: 'post',
        data: $('form#params-form').serialize(),
        dataType: 'json',
        success: function (data) {
            $.handleResults(data, false);
        }
    });
}).on('click', '#download-collection', function (event) {
    event.preventDefault();

    var downloads = [];
    $.each($('input.download-document:checked', '.container-download'), function () {
        downloads.push($(this).val());
    });
    if (downloads.length > 0) {
        window.open('/dejordandownloads/collection?data=' + window.btoa(downloads), '_blank');
    }
}).on('click', '.item-product', function (event) {
    event.preventDefault();

    $('#products .active').removeClass('active');
    $(this).addClass('active');

    var me = $(this),
        catergory = $('#sub a.active').length ? $('#sub a.active').data('category') : null,
        url = '/dejordandownloads/productDownloads/' + me.data('product');

    $('form#params-form input[name="product"]').val(me.data('product'));

    if (catergory) {
        url += '/' + catergory;
    }

    $.showDownloadLoadingIndicator();
    $.ajax({
        url: url,
        type: 'post',
        data: $('form#params-form').serialize(),
        dataType: 'json',
        success: function (data) {
            $.handleResults(data, false, false);
        }
    });
}).on('click', '.categorieSelector button', function () {
    $(this).toggleFilterButton();
    $.filterDownloadsTable();
}).on('click', '.downloads-email', function (event) {
    event.preventDefault();
    var uri = $(this).attr('href');
    $.loadTemplateForModal(event, uri, function (modal) {
        console.log('loaded');
    });
    return false;
}).on('click', 'a.copy-downloads-link', function (event) {
    event.preventDefault();
    const isDownload = $(this).data('is-download');
    var link = $(this).data('download-link');
    if (isDownload === true) {
        link = window.location.origin + $(this).data('download-link');
    }
    navigator.clipboard.writeText(link).then();
}).on('change', 'select.downloads-language-select', function () {
    var selected = $(this).find(':selected'),
        url = $(this).data('url');
    //redirect
    var obj = URI(url);
    //reset query
    obj.search("");
    obj.addQuery('changeDownloadLanguage', selected.val());
    window.location.href = obj.toString();
}).on('change', '.categorieSelector select', function () {
    var selected = $(this).find(':selected'),
        language = selected.val(),
        url = selected.data('target-url');
    $('form#params-form input[name="language"]').val(language);
    $.ajaxCategoriesCall(url, false, false);
});
$(document).ready(function () {
    //on document ready
}).on('submit', '.modal-content form.modal-form, form.form-ajax', function (event) {
    event.preventDefault();
    var isModal = $(this).hasClass('modal-form');
    var disableInputFields = $(this).data('disable');
    if (typeof disableInputFields === "undefined") {
        disableInputFields = true;
    }
    $(this).unbind('submit').submitForm(isModal, disableInputFields);
    return false;
}).on('click', '.modal-content a.forgot-password, .modal-content a.button-login', function (event) {
    event.preventDefault();
    //Modal Login - Button forget password
    var container = $("#modal-container, #customer_login");
    if (container.length > 0) {
        $("#login", container).toggleClass("hidden");
        $("#passwordForget", container).toggleClass("hidden");
    }
    return false;
}).on('click', '.newsletter-submit', function (event) {
    var type = $(this).data('type'),
        formElement = $(this).closest('form').find('[name="type"]');
    $(this).createSpinnerContainer();
    if (formElement.length > 0) {
        formElement.val(type);
    }
});
(function ($) {
    $.setupSticky = function () {
        'use strict';
        var $ = window.jQuery;
        var Waypoint = window.Waypoint;

        /* http://imakewebthings.com/waypoints/shortcuts/sticky-elements */
        function Sticky(options) {
            this.options = $.extend({}, Waypoint.defaults, Sticky.defaults, options);
            this.element = this.options.element;
            this.$element = $(this.element);
            this.createWrapper();
            this.createWaypoint()
        }

        /* Private */
        Sticky.prototype.createWaypoint = function () {
            var originalHandler = this.options.handler;
            this.waypoint = new Waypoint($.extend({}, this.options, {
                element: this.wrapper,
                handler: $.proxy(function (direction) {
                    var shouldBeStuck = this.options.direction.indexOf(direction) > -1;
                    var wrapperHeight = shouldBeStuck ? this.$element.outerHeight(true) : '';
                    this.$wrapper.height(wrapperHeight);
                    this.$element.toggleClass(this.options.stuckClass, shouldBeStuck);
                    if (originalHandler) {
                        originalHandler.call(this, direction)
                    }
                }, this)
            }))
        };
        /* Private */
        Sticky.prototype.createWrapper = function () {
            if (this.options.wrapper) {
                this.$element.wrap(this.options.wrapper)
            }
            this.$wrapper = this.$element.parent();
            this.wrapper = this.$wrapper[0];
        };
        /* Public */
        Sticky.prototype.destroy = function () {
            if (this.$element.parent()[0] === this.wrapper) {
                this.waypoint.destroy();
                this.$element.removeClass(this.options.stuckClass);
                if (this.options.wrapper) {
                    this.$element.unwrap()
                }
            }
        };
        Sticky.defaults = {
            wrapper: '<div class="sticky-wrapper" />',
            stuckClass: 'stuck',
            direction: 'down right'
        };
        Waypoint.Sticky = Sticky
    };

    $.stickyHeader = function () {
        var sticky = new Waypoint.Sticky({
            element: jQuery('.basic-sticky-example'),
            handler: function () {
                if ($('div#loginContent').is(':visible')) {
                    $.handleLoginArea();
                }
            }
        });
    };
}(jQuery));

$(document).ready(function () {
    //sticky header
    if (window.matchMedia("(orientation: landscape)").matches) {
        if ($('.basic-sticky-example').hasClass('Fixed')) {
            $('.basic-sticky-example').removeClass('Fixed');
        }
    }
    if (!jordanb2b.Settings.isShopDesignerAdmin) {
        $.setupSticky();
        $.stickyHeader();
    }
});
(function ($) {
    $.setFlyoutHeight = function () {
        var menuSwiper = null;
        var windowWidth = $(window).width();
        if (windowWidth >= 1024) {
            var maxHeight = 0;
            $('.jd-flyout-list.jd-level-2').each(function () {
                var thisJDLVL2 = $(this).height();
                var self = $(this);
                self.find('ul').each(function () {
                    if (!$(this).hasClass('list-group-submenu')) {
                        var thisH = $(this).height();
                        if (thisH < thisJDLVL2) {
                            $(this).height(thisJDLVL2);
                        }
                    }
                });
            });
            $('.jd-flyout-list.jd-level-4-compact').each(function () {
                var cols = Math.min(3, Math.ceil($(this).find('> li').length / 9));
                var maxLiWidth = Math.floor($(this).width() / cols);
                // $(this).find('> li').css('maxWidth', maxLiWidth + 'px');
                $(this).css('height', 'auto');
            });
        } else {
            $('.jd-flyout-list').css('height', 'auto');
        }
    };

    $.updateMenuInSession = function () {
        if (jordanb2b.Settings.isMenuUpdate) {
            $.ajax({
                url: '/dejordan/ajax/menuUpdate',
                type: 'GET',
                contentType: 'application/json',
                dataType: 'json',
                async: true,
                complete: function (data) {
                    jordanb2b.Settings.isMenuUpdate = false;
                    if (data.responseJSON.mainMenu) {
                        $('#main-navigation').html(data.responseJSON.mainMenu);
                        $.initMainMenu();
                    }
                }
            });
        }
    };

    $.initMainMenu = function () {
        if (!jordanb2b.Settings.isShopDesignerAdmin) {
            var $link = $('.jd-menu-bar-inner-list-item');
            var $ulNav = $('.jd-menu-bar-inner-list');
            var $flyout = $('.jd-flyout-container');
            var $jdHeader = $('.jd-header');
            var $liChildrenA = $('.jd-has-children > a');
            var menuTimeout = null;
            if ($('html').hasClass('touch')) {
                $(document).on('touchstart', function () {
                    $flyout.removeClass('open');
                    $link.removeClass('active').removeClass('no-touch');
                });
            }
            var subnavigations = $('.jd-flyout-list.jd-level-2');
            subnavigations.menuAim({
                activate: activateSubmenu,
                deactivate: deactivateSubmenu,
                rowSelector: "> li"
            });
            var jdlevel3 = $('.jd-flyout-list.jd-level-3');
            jdlevel3.menuAim({
                activate: activateSubmenu,
                enter: activateSubmenu,
                deactivate: deactivateSubmenu,
                exitMenu: deactivatejd3Submenu,
                rowSelector: "> li"
            });
            var jdlevel4 = $('.jd-flyout-list.jd-level-4');
            jdlevel4.menuAim({
                activate: activateSubmenu,
                enter: activateSubmenu,
                deactivate: deactivateSubmenu,
                exitMenu: deactivatejd4Submenu,
                rowSelector: "> li"
            });
            var jdlevel5 = $('.jd-flyout-list.jd-level-5');
            jdlevel5.menuAim({
                exitMenu: deactivatejd5Submenu,
                rowSelector: "> li"
            });

            function deactivatejd3Submenu() {
                setTimeout(function () {
                    $('.jd-level-3.active, .jd-level-3-compact.active, .jd-level-4.active, .jd-level-4-compact.active, .jd-level-5.active').removeClass('active');
                }, window.jordanb2b.Settings.MenuFlyoutIntern);
            }

            function deactivatejd4Submenu() {
                setTimeout(function () {
                    $('.jd-level-4.active, .jd-level-4-compact.active, .jd-level-5.active').removeClass('active');
                }, window.jordanb2b.Settings.MenuFlyoutIntern);
            }

            function deactivatejd5Submenu() {
                setTimeout(function () {
                    $('.jd-level-5.active').removeClass('active');
                }, window.jordanb2b.Settings.MenuFlyoutIntern);
            }

            function activateSubmenu(row) {
                var $row = $(row);
                if ($($row.children('ul')[0])) {
                    $($row.children('ul')[0]).addClass('active');
                }
            }

            function deactivateSubmenu(row) {
                var $row = $(row);
                if ($($row.children('ul')[0])) {
                    $($row.children('ul')[0]).removeClass('active');
                }
            }

            $link.on('mouseenter touchstart', function (event) {
                //remove opened and active elemetns
                var active = $('.active', '.jd-menu-bar');
                var open = $('.open', '.jd-menu-bar');
                var openedFlyout = $(event.relatedTarget);
                var target = $(event.target);
                if (event.type == 'touchstart') {
                    var me = $(this);
                    if (me.children('div.jd-flyout-container').hasClass('open') && target.parent().parent().hasClass('jd-menu-bar-inner-list-item')) {
                        me.children('div.jd-flyout-container').removeClass('open');
                        me.removeClass('active');
                        if ($('div.jd-flyout-container.open').length >= 2) {
                            $('div.jd-flyout-container.open').each(function () {
                                $(this).removeClass('open');
                            });
                        }
                        return;
                    }
                }
                active.each(function () {
                    var self = $(this);
                    if (self.children('a').text() !== openedFlyout.text())
                        self.removeClass('active');
                });
                open.each(function () {
                    var self = $(this);
                    if ($(this).children('a').children('span').text() === openedFlyout.text() ||
                        $(this).siblings('a').text() === openedFlyout.text()) {
                        return;
                    }
                    self.removeClass('open');

                });
                var link = $(this);
                if (!jordanb2b.Settings.isTablet) {
                    var timeoutValue = window.jordanb2b.Settings.MenuFlyout;
                    if ($(event.relatedTarget).parent().parent().hasClass('jd-menu-bar-inner-list-item')) {
                        timeoutValue = window.jordanb2b.Settings.MenuFlyoutIntern;
                    }

                    menuTimeout = setTimeout(function () {
                        $flyout.removeClass('open');
                        $link.removeClass('active');
                        if (link.find('.visited').length > 1) {
                            link.find('.visited').removeClass('visited');
                        }
                        link.addClass('active');
                        link.find($flyout).addClass('open');

                    }, timeoutValue);
                } else {
                    link.addClass('active');
                    link.find($flyout).addClass('open');
                }
                event.stopPropagation();
            });
            $link.on('mouseleave', function () {
                clearTimeout(menuTimeout);
            });
            $jdHeader.on('mouseenter touchstart', function (e) {
                var target = $(e.target);
                if ($('.jd-flyout-container.open').length !== 0) {
                    setTimeout(function () {
                        $flyout.removeClass('open');
                        $link.removeClass('active');
                    }, window.jordanb2b.Settings.MenuFlyout);
                }
            });
            $ulNav.on('mouseleave', function (e) {
                var target = $(e.target);
                if (target.hasClass('jd-flyout-container') ||
                    target.parent('a').parent('li').hasClass('jd-menu-bar-inner-list-item') ||
                    target.parent('li').hasClass('jd-menu-bar-inner-list-item')
                ) {
                    return false;
                }
                setTimeout(function () {
                    $flyout.removeClass('open');
                    $link.removeClass('active');
                }, window.jordanb2b.Settings.MenuFlyout);
                e.stopPropagation();
            });
            $liChildrenA.on('mouseenter', function (e) {
                var target = $(e.target);
                target.parent().parent().find('ul.jd-flyout-list').first().addClass('active');
            });
            $flyout.on('mouseleave', function (e) {
                setTimeout(function () {
                    $flyout.removeClass('open');
                    $link.removeClass('active');
                    $('.visited').removeClass('visited');
                }, window.jordanb2b.Settings.MenuFlyout);
            });
            $flyout.on('mouseenter', function (e) {
                e.stopPropagation();
            });
        }

        // Set Element Gray when in the Navigation
        // Level 3 and Level 4
        $('.jd-level-3, .jd-level-4, .jd-level-5, .jd-level-3 > li , .jd-level-4 > li ,  .jd-level-5 > li').on('mouseenter', function (event) {
            var span = null;
            if ($(event.target.parentElement.parentElement).hasClass('jd-has-children')) {
                span = $(event.target).parent().siblings('a').children('span');
            }
            if ($(event.target).parent().hasClass('jd-has-children')) {
                span = $(event.target).parent().children('a').children('span');
            }
            if (span !== null) {
                //todo
                //just clear failed visted disabled li or better don't let it happen !!!
                //$('.visited').each(function(){
                //    $(this).removeClass('visited');
                //});
                span.addClass('visited');
            }
        });
        $('.jd-level-3, .jd-level-4, .jd-level-5,  .jd-level-3 > li , .jd-level-4 > li, .jd-level-5 > li').on('mouseleave', function (event) {
            var span = null;
            if ($(event.target.parentElement.parentElement).hasClass('jd-has-children')) {
                span = $(event.target).parent().siblings('a').children('span');
            }
            if ($(event.target).parent().hasClass('jd-has-children')) {
                span = $(event.target).parent().children('a').children('span');
            }
            if (span !== null) {
                span.removeClass('visited');
                span.removeAttr('style');
            }
        });
    }
}(jQuery));

$(document).ready(function () {
    //on document ready
    $.setFlyoutHeight();
    $.initMainMenu();
    $.updateMenuInSession();
}).on('click', '.jd-has-children a', function (event) {
    event.stopPropagation();
}).on('click', '#burgerNav', function (event) {
    var $icon = $('#burgerIcon');
    var API = $('#mobileCategoryMenu').data('mmenu');
    if ($icon.hasClass("is-active")) {
        API.close();
    } else {
        API.open();
    }
    API.open();
    API.bind("open:finish", function () {
        setTimeout(function () {
            $icon.addClass("is-active");
            //<i class="fas fa-times"></i>
            //<i class="fas fa-bars"></i>
            //$('#burgerNavImg').attr('src', $('#burgerNavImg').attr('src').replace('burger.png', 'cross.png'));
            $('#burgerNavImg').removeClass('fa-bars').addClass('fa-times');
        }, 100);
    });
    API.bind("close:finish", function () {
        setTimeout(function () {
            $icon.removeClass("is-active");
            //$('#burgerNavImg').attr('src', $('#burgerNavImg').attr('src').replace('cross.png', 'burger.png'));
            $('#burgerNavImg').removeClass('fa-times').addClass('fa-bars');
        }, 100);
    });
}).on('click', '.js-mobile-nav-list > li > a', function (event) {
    $(this).siblings('.js-mobile-nav-list').slideToggle(200);
    $(this).closest('li').toggleClass('open');
});

$(window).on('resize', function () {
    $.setFlyoutHeight();
});
if (navigator && navigator.userAgent && navigator.userAgent.match(/iPhone|iPad|Android.*Mobile|Kindle/)) {
    $(window).on('scroll', function () {
        $('div.jd-flyout-container').removeClass('open').removeClass('active');
        $('li.jd-menu-bar-inner-list-item.active').removeClass('active').addClass('no-touch');
    });
}

function addQuantityCalculatorToDataRow(el, event) {
    event.preventDefault();
    var productId = $(el).data('product');
    var row = $(el).closest('.calculator').attr('data-row');
    $.loadTemplateForModal(event, '/dejordan/ajax/calculator/' + productId, function (modal) {
        return modal.initCalculateAmountForm(row);
    });
}

$(document).ready(function () {

}).on('change', 'input[name^="amounts["]', function () {
    $('#bookmark-update-btn-' + $(this).data('identification')).show();
}).on('click', '.btn-bookmark-update', function (){
    const productId = $(this).data('product-id');
    const listId = $(this).data('list-id');
    const ident = $(this).data('ident');
    const pbident = $(this).data('pba-ident');

    $.ajax({
        url: '/dejordanbookmark/update-list/' + listId + '/' + productId + '/' + pbident,
        data: $('.form-horizontal.calculator.calculator-' + ident).find('input').serialize(),
        type: 'POST',
        async: true,
        always: function (response){
            console.log(response);
        }
    });
    $(this).hide();
}).on('click', '.add-to-cart-single', function (event) {
    event.preventDefault();
    var product = $(this).data('product-id'),
        reload = $(this).data('reload'),
        ident = $(this).data('identifier');

    if (!product) {
        return false;
    }

    var data = {singleProduct: product};
    $.map($('.amount-' + ident).serializeArray(), function (n) {
        data[n.name] = n.value;
    });

    var dataLayer = $(this).data('data-layer');
    $.map($('.product-quantity-' + ident).serializeArray(), function (n){
        data['quantity'] = n.value;
    });
    $.putProductToCart(event, data, null, reload, dataLayer);
    return false;
}).on('click', '.add-to-cart', function (event) {
    event.preventDefault();
    var identifier = $(this).data('identifier'),
        form = $('form.form-calculation-' + identifier + ':visible'),
        url = form.attr("action");
    if (form.length <= 0 || !identifier) {
        return false;
    }

    var dataLayer = $(this).data('data-layer');
    if(dataLayer) {
        try {
            dataLayer['items'][0]['quantity_calculated'] = parseFloat(form.first().find('input#quantity').val().replace(',','.'));
            dataLayer['items'][0]['quantity'] = parseFloat(form.first().find('input#amounts0').val());
            dataLayer['items'][0]['price'] = parseFloat(form.first().find('.price-total').html().replace(',','.').match(/(^\S+)/));
        } catch (e) {
            console.error(e);
        }
    }

    $.putProductToCart(event, form.serialize(), url, undefined, dataLayer);
    return false;
}).on('click', '.select-variants', function (event) {
    event.preventDefault();
    var productId = $(this).data('product');
    if (!productId) {
        productId = $(this).data('product-id');
    }
    if (productId) {
        $.ajax({
            url: '/dejordanproduct/selectvariants/' + productId,
            method: 'GET',
            success: function (response) {
                $.loadModalContent(event, response, 0, 'md', null, null, function () {
                    window.jordanb2b.util.initSelect2();
                });
            }
        });
    }
    return false;
}).on('click', '.add-to-cart-variant-selection', function (event) {
    var variant = $('select.productvariant-selection-modal option:selected');
    var dataLayer = variant.data('data-layer');

    $.putProductToCart(event, {singleProduct: variant.data('product')}, undefined, undefined, dataLayer);
}).on('click', '.add-product-to-relevance', function (event) {
    event.preventDefault();
    var me = $(this),
        productId = me.data('productId');
    if (!productId) {
        return false;
    }

    $.post('/dejordan/relevance-list/add', {productId: productId}, function (response) {
        if (response.status === 'error') {
            $.loadModalContent(event, response.message, 0, 'md', true, 'Fehler');
        } else {
            // switch buttons
            $('.add-product-to-relevance[data-product-id="' + productId + '"]').addClass('hidden');
            $('.product-on-relevance[data-product-id="' + productId + '"]').removeClass('hidden');
            if (response.template) {
                $.loadModalContent(event, response.template, jordanb2b.Settings.Growl.life, 'md');
            }
        }
    });
}).on('click', '.remove-product-relevance', function (event) {
    event.preventDefault();
    var me = $(this);
    $.ajax({
        'url': $(this).attr('href'),
        'type': 'GET',
        'async': true,
        complete: function (data, textStatus) {
            if (data.responseJSON.status === 'success') {
                var target = me.data('target'),
                    containerTarget = $('#' + target)
                if (containerTarget.length > 0) {
                    containerTarget.fadeOut('slow', function () {
                        containerTarget.remove();

                        if ($('.orderItem', '.container-no-left').length === 0) {
                            $('.alert', '.container-no-left').removeClass('hidden');
                        }
                    });
                }
            }
            return true;
        }
    });
}).on('click', '.add-position', function (event) {
    event.preventDefault();
    if ($(this).find('a.fa,a.fas').hasClass('fa-minus-square')) {
        $(this).parents('.calculator').remove();
    } else if ($(this).find('a.fa,a.fas').hasClass('fa-plus-square')) {
        var calc = $(this).closest('.calculator');
        var newRow = calc.clone();
        var idx = calc.data('row') || 0;
        var pattern = new RegExp('\\[' + idx + '\\]"', 'g');
        newRow.html(newRow.html().replace(pattern, '[' + (idx + 1) + ']"'));
        newRow.data('row', idx + 1);
        newRow.insertAfter(calc);
        $.initCalculator(newRow);
        $('[data-toggle="tooltip"]').tooltip();
    } else {
        return false;
    }
    // arrange classes first and last and plux minus icon
    var calcRows = $('.calculator:visible');
    for (var i = 0; i < calcRows.length; i++) {
        if (i == 0) {
            if (!$(calcRows[i]).hasClass('first')) {
                $(calcRows[i]).addClass('first');
            }
        } else {
            $(calcRows[i]).removeClass('first');
        }
        if (i == (calcRows.length - 1)) {
            if (!$(calcRows[i]).hasClass('last')) {
                $(calcRows[i]).addClass('last');
            }
            $(calcRows[i]).find('a.fa,a.fas').removeClass('fa-minus-square').addClass('fa-plus-square');
        } else {
            $(calcRows[i]).removeClass('last');
            $(calcRows[i]).find('a.fa,a.fas').removeClass('fa-plus-square').addClass('fa-minus-square');
        }
        $(calcRows[i]).attr('data-row', i);
        $(calcRows[i]).find('a.quantitycalculator').off('click').on('click', function (event) {
            addQuantityCalculatorToDataRow($(this), event);
        });
    }
}).on('submit', 'form.form-document-ajax', function (event) {
    var me = $(this);
    var callbackFunction = function (callerType, response) {
        if ('modal.submit.complete' === callerType) {
            if (response.success) {
                me.closest('.modal').modal('hide');
            } else {
                me.closest('.modal').find('.alert-danger').removeClass('hidden');
                var customContainer = me.closest('.modal').find('.alert .custom-container'),
                    defaultContainer = me.closest('.modal').find('.alert .default-container');
                if (response.message) {
                    defaultContainer.addClass('hidden');
                    customContainer.html(response.message).removeClass('hidden');
                } else {
                    defaultContainer.removeClass('hidden');
                    customContainer.addClass('hidden');
                }
            }
            return false;
        }
    };
    me.closest('.modal').find('.alert-danger').addClass('hidden');
    callbacks.add(callbackFunction);
    $(this).unbind('submit').submitForm();
    return false;
}).on('click', 'a.quantitycalculator', function (event) {
    addQuantityCalculatorToDataRow($(this), event);
});

$(document).ready(function () {
    'use strict';

    if ($('.btn-registration-search-store').length > 0) {
        let store = $('.btn-registration-search-store').closest('form').find('input#store').val();
        if (store.length > 0) {
            $('.btn-registration-search-store').trigger('click', [false]);
        }
    }
}).on('submit', '.registration-wizard form', function (event) {
    $('button[type="submit"]', $(this)).spinnerButton('start');
}).on('keydown', '#address-input,.prevent-form-submission', function (event) {
    // check if enter
    if (event.keyCode === 13) {
        event.preventDefault();
        return false;
    }
}).on('click', '.btn-registration-search-store', function (event, resetStore) {
    event.preventDefault();

    if (typeof resetStore === "undefined") {
        resetStore = true;
    }

    let me = $(this),
        form = me.closest('form'),
        action = me.data('action');

    // reset current selected store
    if (resetStore) {
        form.find('input#store').val('');
    }

    me.createSpinnerContainer();
    me.spinnerButton('start');

    $.ajax({
        url: action,
        type: 'post',
        data: form.serialize(),
        complete: function (data) {
            form.find('div.stores-content').html(data.responseText);
            refreshMapMarkers();
        }
    }).always(function () {
        me.spinnerButton('stop');
    });
}).on('click', '.btn-registration-select-store', function (event) {
    event.preventDefault();

    let store = $(this).data('store');

    $('.result-office-item', '.stores-content').removeClass('selected');
    $('.result-office-item[data-ident="' + store + '"]', '.stores-content').addClass('selected')

    $(this).closest('form').find('input#store').val($(this).data('store'));
});

function refreshMapMarkers() {
    // @see stores.event.js
    mapSearch.removeMarkers();

    let bounds = [],
        resultItems = $('div.result-office-item');
    if (resultItems.length > 0) {
        resultItems.each(function (index) {
            let lat = $(this).data('lat'),
                lng = $(this).data('lng'),
                ident = $(this).data('ident'),
                infoElement = $("#office-item-overlay-" + ident);
            if (lat && lng) {
                var latlng = new google.maps.LatLng(lat, lng);
                bounds.push(latlng);
                mapSearch.addMarker({
                    lat: lat,
                    lng: lng,
                    title: $(this).text(),
                    icon: $(this).data('icon'),
                    animation: google.maps.Animation.DROP,
                    infoWindow: {
                        content: infoElement.html()
                    }
                });
                if (resultItems.length == 1) {
                    mapSearch.setCenter(lat, lng);
                    mapSearch.setZoom(17);
                }
            }
        });
        if (resultItems.length > 1) {
            mapSearch.fitZoom();
        }
    }
}
$(document).ready(function () {
    'use strict';
    Parsley.addValidator('checkOneSelected', {
        messages: {
            de: jordanb2b.Dictionary.Rewards.checkOneSelected,
            en: jordanb2b.Dictionary.Rewards.checkOneSelected
        },
        requirementType: 'string',
        validate: function (_value, requirement, instance) {
            var options = instance.$element.closest('form').find(requirement + ' option:selected[value!="0"]');
            return options.length > 0;
        }
    });

    Parsley.addValidator('checkUnique', {
        messages: {
            de: jordanb2b.Dictionary.Rewards.checkUnique,
            en: jordanb2b.Dictionary.Rewards.checkUnique
        },
        requirementType: 'string',
        validate: function (_value, requirement, instance) {
            if (_value > 0) {
                var options = instance.$element.closest('form').find(requirement + ' option:selected[value="' + _value + '"]');
                return options.length <= 1;
            }
            return true;
        }
    });

    Parsley.addValidator('checkOrder', {
        messages: {
            de: jordanb2b.Dictionary.Rewards.checkOrder,
            en: jordanb2b.Dictionary.Rewards.checkOrder
        },
        requirementType: 'string',
        validate: function (_value, requirement, instance) {
            if (_value > 1) {
                //check if a value < current value is selected
                var maxOptions = instance.$element.closest('form').find(requirement + ':first option[value!="0"]').length;
                var options = instance.$element.closest('form').find(requirement + ' option:selected[value!="0"]');
                if (options.length === 1 && _value > 1) {
                    //only one selected and _value > 1
                    return false;
                }

                if (options.length === maxOptions) {
                    return true;
                }

                var valid = false,
                    num = 0, max = 0;

                for (var i = 1; i < _value; i++) {
                    var currentOption = instance.$element.closest('form').find(requirement + ' option:selected[value="' + i + '"]');
                    if (currentOption.length > 0) {
                        num++;
                        if (max < i) {
                            max = i;
                        }
                    }
                }
                if (num < (_value - 1)) {
                    return false;
                }
                $(options).each(function () {
                    var optionValue = $(this).val();
                    if (parseInt(optionValue) === 1) {
                        valid = true;
                        return false;
                    }
                    if (optionValue < _value) {
                        valid = false;
                        return false;
                    }
                });
                return valid;
            }
            return true;
        }
    });

    var parsleyOptions = {
        successClass: 'has-success',
        errorClass: 'has-error',
        errorsWrapper: '<span class="help-block"></span>',
        errorTemplate: '<span class="display-block"></span>',
        classHandler: function (ParsleyField) {
            return ParsleyField.$element.closest('.form-group');
        },
        errorsContainer: function (ParsleyField) {
            return ParsleyField.$element.parents('.form-group');
        }
    };
    if ($('#rewards-product-form').length > 0) {
        $('#rewards-product-form').parsley(parsleyOptions);
    }
    if ($('#rewards-customer-form').length > 0) {
        $('#rewards-customer-form').parsley(parsleyOptions);
    }
}).on('change', '#rewards-product-form select', function (event) {
    $('#rewards-product-form').parsley().validate();
}).on('submit', '#rewards-product-form', function (event) {
    'use strict';
    var skip = $(this).data('skip-modal'),
        options = $(this).find('select option:selected[value!="0"]');
    if (!skip) {
        if (options.length < 3) {
            //show modal
            $('#confirm-selection').modal('show');
            return false;
        }
    }
}).on('click', '#handle-rewards-login', function (event) {
    event.preventDefault();
    $.loadModal(event, '/customer/ajax-login');
    return false;
}).on('click', '#submit-reward-form', function (event) {
    $('#rewards-product-form').attr('data-skip-modal', 1).submit();
});
$(document).ready(function () {
    //on document ready
}).on('click', '.salesressource-link', function (event) {
    event.preventDefault();
    var url = $(this).data('url'),
        href = $(this).attr('href');
    $.ajax({
        url: href,
        type: 'POST',
        async: true,
        complete: function (data, textStatus) {
            if (data.responseJSON && true === data.responseJSON.success) {
                //redirect
                window.location.href = url;
            }
            return true;
        }
    });
    return false;
});
$(document).ready(function () {
    if ($('.algolia-field:visible,.algolia-field-force').length > 0) {
        $('.algolia-field:visible,.algolia-field-force').initAlgolia();
    }
}).on('click', '.search-container .btn-filter', function (event) {
    event.preventDefault();
    $('.algolia-facet-container').slideToggle();
});
(function ($) {
    $.fn.extend({
        initProductVariantSelection: function () {
            return this.each(function (i, elem) {
                var variant = $(elem);
                if (variant.length > 0) {
                    variant.select2({
                        theme: 'bootstrap',
                        templateResult: addDropdownImage,
                        templateSelection: addDropdownImage,
                        minimumResultsForSearch: 8,
                        dropdownAutoWidth: false,
                        forcebelow: true,
                        width: '100%'
                    }).on('select2:open', function (e) {
                        if (jordanb2b.Settings.isTablet || jordanb2b.Settings.isMobile) {
                            $('.select2-search input').prop('focus', false);
                        }
                    });
                    $('.select2-selection__rendered').attr('title', '');
                    variant.on('change', function () {
                        var optionSelected = $('option:selected', this);
                        var url = optionSelected.data('url');
                        var target = optionSelected.data('img-target');
                        if (url) {
                            window.location.href = url;
                            return true;
                        } else if (target) {
                            var img = optionSelected.data('img').replace('/product-thumb-b2b/', "/product-list-marketplace/").replace('/thumbnail/', "/panel/");
                            $(target).attr('src', img);
                        }
                        //return false;
                    });
                }
            });
        }
    });
}(jQuery));

var select2ShowImgAllowed = false,
    select2ShowImgPositions = null;

/**
 * add dropdown image
 * @param opt
 * @returns {*}
 */
function addDropdownImage(opt) {
    if (!opt.id) {
        return opt.text;
    }

    var form = opt.element.form;
    var formId = null;
    if (form) {
        formId = $(opt.element.form).attr('id');
    }
    var optimage = $(opt.element).data('img');
    var optimagetarget = $(opt.element).data('img-target');
    var optinstock = $(opt.element).data('instock');
    var optexpire = $(opt.element).data('expire');
    if (!optimage) {
        return opt.text;
    } else {
        var html = '<span%3%><img class="variant-zoom-image" %1% src="' + optimage + '" /> <span class="truncate-text%4%">' + opt.text + '</span>%2%</span>';
        var span2 = '';
        if (optinstock > 0) {
            //span2 += '<span class="instock"></span>';
            span2 += '<i class="fas fa-fw fa-dolly"></i>';
        } else if (optinstock === 0) {
            //span2 += '<span class="outofstock"></span>';
            span2 += '<i class="fas fa-fw fa-warehouse-alt"></i>';
        } else if (optinstock == -2) {
            span2 += '<i class="far fa-fw fa-truck-moving"></i>';
        }
        if (optexpire === 1) {
            span2 += '<span class="jd-count">A</span>';
        }
        if (null !== formId && formId !== undefined && formId !== 'undefined') {
            html = html.replace('%1%', 'data-formid="' + formId + '"');
        } else if (optimagetarget) {
            html = html.replace('%1%', 'data-img-target=" ' + optimagetarget + '"');
        } else {
            html = html.replace('%1%', '');
        }
        if (span2.length > 0) {
            html = html.replace('%2%', span2).replace('%3%', ' class="with-stock"');
        } else {
            html = html.replace('%2%', '').replace('%3%', '');
        }
        var extraInfo = (span2.match(/<span/g) || []).length;
        if (extraInfo == 0) {
            html = html.replace('%4%', '');
        } else {
            html = html.replace('%4%', ' len-70');
        }
        return $(html);
    }
}

$(document).ready(function () {
    $('select.productvariant-selection').initProductVariantSelection();
}).on('mouseenter', '.select2-results li.select2-results__option', function () {
    //select2 images
    var windowWidth = $(window).width();
    if (windowWidth <= 1024) {
        return true;
    }
    if (true == select2ShowImgAllowed) {
        var imgElement = $('img.variant-zoom-image', $(this));
        if (imgElement.length > 0) {
            var optimage = imgElement.attr('src'),
                formId = imgElement.data('formid'),
                target = imgElement.data('img-target');

            if (formId !== undefined && formId !== 'undefined') {

                var imageContainer = $('<div class="variant-zoom-image-container"/>');
                var img = $('<img class="variant-zoom-image-large" src="' + optimage.replace('/product-thumb-b2b/', "/product-list-marketplace/").replace('/thumbnail/', "/panel/") + '" />');

                imageContainer.append(img);
                // $('body').append(imageContainer);
                imageContainer.insertAfter($('form#' + formId + ' select'));

                var parentFancybox = $('form#' + formId).parents('#fancybox-content');
                if (parentFancybox.length > 0) {
                    $('#fancybox-content').css({'overflow': 'visible'});
                    $('#fancybox-content div:first').css({'overflow': 'visible'});
                }

                img.css({
                    top: 0,
                    left: '-' + (img.outerWidth() + 2) + 'px'
                });
            } else if (target !== undefined && target !== 'undefined') {
                $(target).attr('src', optimage.replace('/product-thumb-b2b/', "/product-list-marketplace/").replace('/thumbnail/', "/panel/"));
            }
        }
    } else {
        $('img.variant-zoom-image-large').remove();
        select2ShowImgAllowed = true;
    }
}).on('mouseleave', '.select2-results li.select2-results__option', function () {
    $('div.variant-zoom-image-container').remove();
}).on('select2:close', 'select', function () {
    select2ShowImgAllowed = true;
    select2ShowImgPositions = null;
    $('div.variant-zoom-image-container').remove();
}).on('select2:open', 'select', function () {
    select2ShowImgAllowed = false;
    select2ShowImgPositions = null;
    $('div.variant-zoom-image-container').remove();
    setTimeout(function () {
        var images = [];
        $('img.variant-zoom-image').each(function () {
            images.push($(this).attr('src').replace('/thumbnail/', "/product-list-marketplace/"));
        });
        $.preloadImages(images);
    }, 500);
});
var storeMaps = [];
var mapSearch;
$(document).ready(function () {
    //detail tabs
    if ($('#detailInfosBranch').length > 0) {
        $('a[data-toggle="tab"]:first', '#detailInfosBranch').trigger('click');
    }
    //map search
    if ($('#mapSearch').length > 0) {
        var bounds = [],
            lat = 51.165691,
            lng = 10.451526,
            zoom = 6;
        if ($('input.center-location-lat').length > 0) {
            lat = $('input.center-location-lat').val();
        }
        if ($('input.center-location-lng').length > 0) {
            lng = $('input.center-location-lng').val();
        }

        if ($('input.map-zoom').length > 0) {
            zoom = parseInt($('input.map-zoom').val());
        }

        mapSearch = new GMaps({
            div: '#mapSearch',
            zoom: zoom,
            minZoom: zoom,
            lat: lat,
            lng: lng,
            zoomControl: true,
            zoomControlOpt: {
                style: 'SMALL',
                position: 'RIGHT_BOTTOM'
            }
        });

        var resultItems = $('div.result-office-item');
        if (resultItems.length > 0) {
            resultItems.each(function (index) {
                var lat = $(this).data('lat'),
                    lng = $(this).data('lng'),
                    ident = $(this).data('ident'),
                    infoElement = $("#office-item-overlay-" + ident);
                if (lat && lng) {
                    var latlng = new google.maps.LatLng(lat, lng);
                    bounds.push(latlng);
                    mapSearch.addMarker({
                        lat: lat,
                        lng: lng,
                        title: $(this).text(),
                        icon: $(this).data('icon'),
                        animation: google.maps.Animation.DROP,
                        infoWindow: {
                            content: infoElement.html()
                        }
                    });
                    if (resultItems.length == 1) {
                        mapSearch.setCenter(lat, lng);
                        mapSearch.setZoom(17);
                    }
                }
            });
            if (resultItems.length > 1) {
                mapSearch.fitZoom();
            }
        } else if ($('div.office-item').length > 0) {
            $('div.office-item').each(function (index) {
                var lat = $(this).data('lat'),
                    lng = $(this).data('lng'),
                    ident = $(this).data('ident'),
                    infoElement = $("#office-item-overlay-" + ident);
                if (lat && lng) {
                    var latlng = new google.maps.LatLng(lat, lng);
                    bounds.push(latlng);
                    mapSearch.addMarker({
                        lat: lat,
                        lng: lng,
                        title: $(this).text(),
                        icon: $(this).data('icon'),
                        animation: google.maps.Animation.DROP,
                        infoWindow: {
                            content: infoElement.html()
                        }
                    });
                }
            });
            //mapSearch.fitZoom();
        }
    }

    //map(s) detail
    var mapsDetail = $('#map');
    if (mapsDetail.length > 0) {
        mapsDetail.each(function (index) {
            var mapDetail = new GMaps({
                div: '#map',
                lat: $(this).data('map-lat'),
                lng: $(this).data('map-lng'),
                zoom: 17,
                zoomControl: true,
                zoomControlOpt: {
                    style: 'SMALL',
                    position: 'RIGHT_BOTTOM'
                }
            });

            mapDetail.addMarker({
                lat: $(this).data('map-lat'),
                lng: $(this).data('map-lng'),
                icon: $(this).data('map-icon')
            });

            if (mapsDetail.data('map-type')) {
                storeMaps[mapsDetail.data('map-type')] = mapDetail;
            }
        });
    }

    if ($('.branch-image-slider').length > 0) {
        $('.branch-image-slider').slick({
            autoplay: true,
            arrows: false,
            dots: true,
            speed: 600,
            lazyLoad: 'ondemand',
            infinite: true
        });
    }
}).on('click', '#showMap', function () {
    //hide
    $('.branch-image-slider, #opening-hours, #showMap').addClass('hidden');
    //show
    $('#map, #calculateRoute, #backToOpeningHours').removeClass('hidden');
    //init map
    var ident = $(this).data('map-type'),
        map = storeMaps[ident];
    if (map) {
        map.refresh();
        map.fitZoom();
        map.setZoom(17);
    }
}).on('click', '#backToOpeningHours', function () {
    //show
    $('.branch-image-slider, #opening-hours, #showMap').removeClass('hidden');
    $(".branch-image-slider").slick('setPosition');
    //hide
    $('#map, #calculateRoute, #backToOpeningHours').addClass('hidden');
});


var player     = {};
var iframe     = {};
var qs         = document.querySelector.bind(document);
var YTdeferred = $.Deferred();

var youtubeParser = function (url) {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return '';
    }
};

var onYouTubeIframeAPIReady = function() {
    YTdeferred.resolve(window.YT);
};

var onPlayerReady = function(event, id) {
    iframe[id] = qs('#player-' + id);
};

var playFullscreen = function (id) {
    player[id].playVideo();//won't work on mobile

    var element           = iframe[id];
    var requestFullScreen = element.requestFullScreen || element.mozRequestFullScreen || element.webkitRequestFullScreen;

    if (requestFullScreen) {
        requestFullScreen.bind(element)();
    }
};

var submitSearch = function() {
    var url = new URL('/dejordanvideos/search', document.baseURI);

    url.searchParams.set('search', $('input.videos-search').val());
    window.location.href = url.href;
};

var alignModal = function() {
    var modalDialog = $(".modal-dialog");

    modalDialog.css("margin-top", Math.max(0, ($(window).height() - modalDialog.height()) / 2));
};

$(document).ready(function () {
    YTdeferred.done(function() {
        $('.player').each(function () {
            var id = $(this).data('id');

            player[id] = new YT.Player('player-' + id, {
                width: '100%',
                height: 'auto',
                videoId: youtubeParser($(this).data('src')),
                fullscreen: 1,
                events: {
                    'onReady': function(event) {
                        onPlayerReady(event, id);
                    },
                }
            });
        });
    });

    var descriptions = $('.video-description');

    descriptions.each(function() {
        if (($(this)[0].offsetHeight < $(this)[0].scrollHeight) || ($(this)[0].offsetWidth < $(this)[0].scrollWidth)) {
            $(this).siblings('.read-more').show();
        }
    });

    var modalDialogs = $(".modal-dialog.video-modal");

    modalDialogs.each(function() {
        $(this).css("margin-top", Math.max(0, ($(window).height() - $(this).height()) / 2));
    });
    
}).on('change', 'select.videos-type-select', function () {
    var selected = $(this).find(':selected'),
        url = window.location.href;
    //redirect
    var url = new URL(url);

    url.searchParams.set('changeType', selected.val());
    window.location.href = url.href;
}).on('change', 'select.videos-language-select', function () {
    var selected = $(this).find(':selected'),
        url = window.location.href;
    //redirect
    var url = new URL(url);

    url.searchParams.set('changeLanguage', selected.val());
    window.location.href = url.href;
}).on('change', 'select.videos-search-type-select', function () {
    var selected = $(this).find(':selected'),
        url = window.location.href;
    //redirect
    var url = new URL(url);

    url.searchParams.set('changeSearchType', selected.val());
    window.location.href = url.href;
}).on('change', 'select.videos-search-sort-select', function () {
    var selected = $(this).find(':selected'),
        url = window.location.href;
    //redirect
    var url = new URL(url);

    url.searchParams.set('changeSearchSort', selected.val());
    window.location.href = url.href;
}).on('click', 'input[type="button"].play-fullscreen', function() {
    playFullscreen($(this).data('id'));
}).on('click', 'a.video-modal-open', function (e) {
    e.preventDefault();

    var self = $(this);

    $.ajax({
        method: 'get',
        url: '/dejordanvideos/getAcceptVideoTerms',
        success: function (data) {
            if(data.isLoggedIn == false) {
                return self.checkLoginModal(e);
            } 

            if (data.videoTermsAccepted == true) {
                return $(self.data('target')).modal('show');
            } 

            $('.error-accept-video-terms').hide();
            
            return $(self.data('target-terms')).modal('show');
        }
    });
}).on('submit', 'form[name="acceptDownloadTerms"]', function (e) {
    e.preventDefault();

    var videoId = $(this).data('video-id');

    var termsAccepted = $(this).find('input[name="acceptVideoterms"]').is(':checked');
    var modal = $('#termsAcceptModal-' + videoId);

    $.ajax({
        method: 'post',
        url: '/dejordanvideos/acceptVideoTerms',
        data: {
            accept: termsAccepted == true ? 1 : 0
        },
        success: function(data) {
            $('.error-accept-video-terms').hide();

            if(data.success == true) {
                if (data.videoTermsAccepted == true) {
                    modal.modal('hide');
                    return $('#videoModal-' + videoId).modal('show');
                }

                $('.error-accept-video-terms').show();
            }
        }
    });
}).on('click', 'div.videos-pagination-container .paginate_button', function () {
    var url = window.location.href;
    var url = new URL(url);

    url.searchParams.set('page', $(this).find('a').data('page'));
    window.location.href = url.href;
}).on('click', '.videos-scroll-top', function () {
    $("html, body").animate({ scrollTop: 0 });
}).on('click', 'a.read-more', function () {
    var description = $(this).siblings('.video-description');

    description.css({
        height: "auto",
        overflow: "auto",
        display: "block"
    });

    var height = $(this).parents('.video-container').height();

    $('.video-container').css('height', height);

    $(this).hide();
    $(this).siblings('.read-less').show();
}).on('click', 'a.read-less', function () {
    var description = $(this).siblings('.video-description');

    description.css({
        height: "40px",
        overflow: "hidden",
        display: "-webkit-box"
    });

    $('.video-container').css('height', 'auto');

    $(this).hide();
    $(this).siblings('.read-more').show();
}).on('keyup', 'input.videos-search', function (event) {
    if(event.keyCode === 13) {
        return submitSearch();
    }

    var search = $('input.videos-search').val();

    if(search.length < 1) {
        $('.aa-suggestions.videos').empty();
        $('.aa-dropdown-menu.videos').hide();
        return;
    }

    $.ajax({
        method: "post",
        url: '/dejordanvideos/query',
        data: {
            'search': search
        },
        success: function(data) {
            $('.aa-suggestions.videos').empty();

            if(data.length < 1) {
                return $('.aa-dropdown-menu.videos').hide();
            }
            
            $('.aa-dropdown-menu.videos').show();

            data.forEach(function(item) {
                var regEx       = new RegExp(search, "i");
                var replaceMask = item.name.match(regEx);
                var name        = replaceMask == null ? item.name : item.name.replace(regEx, '<em>' + (replaceMask.length > 1 ? replaceMask[0] : replaceMask) + '</em>');

                var queryResult = $('<div class="aa-suggestion video" role="option" data-query="' + item.name + '"><span data-id="d-9783" style="white-space: normal;"><span>' + name + '</span><small>' + item.type + '</small></span></div>');
                $('.aa-suggestions.videos').append(queryResult);
            });
        }
    });
}).on('mouseover', '.aa-suggestion.video', function () {
    $(this).css('background-color', '#eee');   
}).on('mouseleave', '.aa-suggestion.video', function () {
    $(this).css('background-color', '#fff');
}).on('click', '.aa-suggestion.video', function () {
    $('input.videos-search').val($(this).data('query'));
    $('.aa-suggestions.videos').empty();
    $('.aa-dropdown-menu.videos').hide();
    
    return submitSearch();
}).on('click', '.video-category-link', function (event) {
    event.preventDefault();

    var url = new URL($(this).attr('href'), document.baseURI);

    var urlParams = new URLSearchParams(window.location.search);

    urlParams.forEach(function(value, key) {
        url.searchParams.set(key, value);
    })

    window.location.href = url.href;
}).on('click', '.video-category-main-link', function (event) {
    event.preventDefault();

    var categoryId = $(this).data('id');

    if ($('#video-sub-categories-' + categoryId).is(':visible')) {
        $('#video-sub-categories-' + categoryId).hide();
    } else {
        $('.video-sub-categories').hide();
        $('#video-sub-categories-' + categoryId).show();
    }
}).on('click', '.video-terms', function () {
    $(this).parents('.modal').modal('hide');
    $('#termsModal').modal('show');
}).on('click', '.videos-search-button', submitSearch);
var widgetPopoverBlocked = false;
var widgetSwiper;
(function ($) {
    $.initWidgetSwiper = function () {
        if (widgetSwiper) {
            widgetSwiper.destroy();
        }
        $('.widget-swiper').each(function () {
            var me = $(this),
                wrapperHeight = me.closest('.grid-stack-item').outerHeight();

            if (0 !== wrapperHeight) {
                var h = wrapperHeight - 111,
                    items = Math.floor(h / 80);
            }

            widgetSwiper = new Swiper(me, {
                direction: 'vertical',
                slidesPerView: 'auto',
                autoHeight: true,
                mousewheelControl: true,
                mousewheelSensitivity: 4,
                spaceBetween: 10,
                nextButton: '.jd-popup-list-next',
                prevButton: '.jd-popup-list-prev',
                onScroll: function (slider, e) {
                    $('[data-toggle="popover"],[data-original-title]').closePopovers(e);
                },
                onSliderMove: function (swiper, e) {
                    $('[data-toggle="popover"],[data-original-title]').closePopovers(e);
                },
                onReachEnd: function (swiper) {
                    var totalItems = swiper.imagesLoaded;
                    //fetch content
                    var form = $('form#popover-settings-form');
                    var page = (totalItems / $('input[name="itemsperpage"]', form).val()) + 1;
                    $('input[name="page"]', form).val(page);
                    widgetPopoverBlocked = true;
                    form.fetchWidgetContent(page, function () {
                        widgetPopoverBlocked = false;
                    });
                }
            });
        });
    };

    $.fn.extend({
        fetchWidgetContent: function (page, callback) {
            return this.each(function (i, elem) {

                var form = $(elem),
                    type = form.data('type').toString();

                var loader = $('.swiper-wrapper .widget-loading');
                if (page !== 1 && loader.length === 0) {
                    widgetPopoverBlocked = false;
                    return false;
                }

                loader.removeClass('invisible');

                $.ajax({
                    url: form.attr('action'),
                    type: 'POST',
                    data: form.serialize(),
                    async: true,
                    success: function (response) {
                        if ('product' === type) {
                            var container = $('#product-swiper-' + response.identifier);
                            if (container.length > 0) {
                                $('.toggle-icon', container).addClass('hidden');
                                $('.media-heading', container).addClass('media-heading-normal');
                                if (response.keep) {
                                    $('.toggle-keep', container).removeClass('hidden');
                                    //daysRemain
                                    if (response.daysRemain) {
                                        $('.toggle-keep .icon-info', container).html(response.daysRemain).removeClass('hidden');
                                    }
                                } else if (response.presented) {
                                    $('.toggle-presented', container).removeClass('hidden');
                                } else {
                                    $('.toggle-default', container).removeClass('hidden');
                                }

                                //close all popovers
                                $('[data-toggle="popover"],[data-original-title]').closePopovers(null, true);
                                //fetch content
                                widgetPopoverBlocked = true;
                                $('input[name="page"]', 'form#popover-settings-form').val(1);
                                $('form#popover-settings-form').fetchWidgetContent(1, function () {
                                    widgetPopoverBlocked = false;
                                });
                            }
                        } else if ('fetch' === type) {
                            //remove old loaders
                            loader.remove();

                            if (page <= 1) {
                                $('.widget-swiper-container', '.widget-latest-articles').html(response);
                                $.initWidgetSwiper();
                            } else {
                                if (widgetSwiper) {
                                    var lastIndex = widgetSwiper.activeIndex;
                                }
                                $('.widget-swiper-container .swiper-wrapper', '.widget-latest-articles').append(response);
                                $.initWidgetSwiper();
                                if (widgetSwiper && lastIndex) {
                                    widgetSwiper.slideTo(lastIndex);
                                }
                            }
                            $('.widget-loading').addClass('invisible');
                        }
                    }
                }).always(function () {
                    if (callback && typeof(callback) === 'function') {
                        callback();
                    }
                });

            });
        },
        closePopovers: function (event, force) {
            if (typeof force === "undefined") {
                force = false;
            }
            return this.each(function (i, elem) {
                //the 'is' for buttons that trigger popups
                //the 'has' for icons within a button that triggers a popup
                if (true === force || (!$(elem).is(event.target) && $(this).has(event.target).length === 0 && $('.popover').has(event.target).length === 0)) {
                    (($(elem).popover('destroy').data('bs.popover') || {}).inState || {}).click = false  // fix for BS 3.3.6
                }
            });
        }
    });
}(jQuery));

$(document).ready(function () {
    var initload = $('.widget-swiper-container[data-init]');
    if (initload.length > 0) {
        $('#' + initload.data('init')).fetchWidgetContent(1);
    } else {
        setTimeout(function () {
            $.initWidgetSwiper();
        }, 500);
    }
}).on('click', '.widget-latest-articles .toggle-popover', function (event) {
    if (true === widgetPopoverBlocked) {
        return false;
    }
    var me = $(this),
        target = me.data('target'),
        additionalClass = me.data('large') ? ' popover-large' : '';
    if (target) {
        //close other popovers
        //$('.toggle-popover').not(this).popover('hide');
        $('[data-toggle="popover"],[data-original-title]').closePopovers(event);
        //create popover
        me.popover({
            container: 'body',
            html: true,
            template: '<div class="popover' + additionalClass + '" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
            content: function () {
                return $('#' + target).html();
            },
            placement: function (context, source) {

                var rightPosition = $(window).width();
                if ((rightPosition - ($(source).offset().left + 125)) <= 0) {
                    return "left";
                }

                var bottomPosition = $(window).scrollTop() + $(window).height();
                if ((bottomPosition - $(source).offset().top) >= 200) {
                    return "bottom";
                }
                return "top";
            }
        }).popover('show');

        me.on('shown.bs.popover', function () {
            $('input.checkbox-switch:visible').bootstrapSwitch({
                size: 'small',
                onText: '<i class="fas fa-check">',
                offText: '<i class="fas fa-remove">',
                onColor: 'success',
                offColor: 'danger',
                inverse: true
            });

        }).on('hidden.bs.popover', function (event) {
            $('input.checkbox-switch', $('#' + target)).bootstrapSwitch('destroy');
        });
    } else {
        me.popover('show');
    }
    return false;
}).on('click touch', function (event) {
    $('[data-toggle="popover"],[data-original-title]').closePopovers(event, true);
}).on('switchChange.bootstrapSwitch', 'input.checkbox-switch:visible', function (event, state) {
    //submit form if available
    var me = $(this),
        formIdentifier = me.data('form');
    me.bootstrapSwitch('disabled', true);

    if (formIdentifier) {
        var form = $('#' + formIdentifier);
        $('input[name="page"]', form).val(1);
        if (form.length > 0) {
            var elements = $('input[name="' + me.attr('name') + '"]', form);
            elements.prop('checked', state).val(state);
            if (true === state) {
                elements.attr('checked', 'checked');
            } else {
                elements.removeAttr('checked');
            }

            form.fetchWidgetContent(1, function () {
                me.bootstrapSwitch('disabled', false);
            });
        }
    }
});
function submitMediaForm(event, form) {
    $(form).find('.alert').addClass('hidden');

    $.ajax({
        url: $(form).attr('action'),
        'type': 'POST',
        'data': $(form).serialize(),
        'async': true,
        success: function (response) {
            let title = $(form).closest('.modal').find('.modal-title').html();
            // hide error message(s)
            $(form).find('.alert').addClass('hidden');
            // close modal
            $(form).closest('.modal').modal('hide');
            // load new modal from template
            $.loadModalContent(
                event,
                response.template,
                0,
                'md',
                true,
                title,
                function (modal) {
                    $('.modal-title', modal).addClass('truncate-text');
                }
            );
        }
    }).fail(function (jqXHR, textStatus, errorThrown) {
        if (jqXHR.responseJSON.message) {
            // show error message(s)
            $(form).find('.alert').html(jqXHR.responseJSON.message);
            $(form).find('.alert').removeClass('hidden');
        }
    });
}

function initMediaWizard(container) {
    document.addEventListener("wz.ready", function (e) {
        if (!e.detail.elem.classList.contains('is-media-wizard')) {
            return;
        }

        // fix for dependency container
        Array.from(e.detail.elem.querySelectorAll('[data-toggle="show-on-change"]'))
            .forEach((element, index) => {
                element.classList.remove('binded');
            });
        toggleOnChange($);
    });

    const wizardContainer = container.querySelector('.is-wizard');
    if (wizardContainer) {
        let selector = '.is-media-wizard-' + wizardContainer.dataset.id,
            args = {
                'wz_class': selector,
                'wz_button': '.btn',
                'form': true,
                'nav': false,
                'highlight': false,
                'validation_callback': function (wz_content, formData) {
                    let check = false;
                    for (let e of formData) {
                        if (e.classList.contains('check-conversions')) {
                            check = true;
                        }
                    }

                    if (check) {
                        let steps = wz_content.querySelectorAll(this.wz_step);
                        let target = steps[this.getCurrentStep()];

                        let checked = 0;
                        for (let e of formData) {
                            e.checked && checked++
                        }

                        if (checked === 0) {
                            formData[0].setCustomValidity(target ? target.dataset.error : 'You must check at least one checkbox');
                            formData[0].reportValidity();

                            return {
                                "error": true,
                                "target": [].push(formData[0])
                            };
                        }

                        formData[0].setCustomValidity('');
                    }

                    return {
                        "error": false,
                        "target": []
                    };
                }
            };

        let wizard = new Wizard(args);

        wizard.init();

        document.querySelector(selector).addEventListener("wz.form.submit", function (e) {
            // submit form
            submitMediaForm(e, e.target.querySelector('.media-download-form'));
        });

        document.querySelector(selector).addEventListener("wz.btn.prev", function (e) {
            $(e.target).find('.media-download-form .alert').addClass('hidden');
        });
    }
}

function toggleWizardOrientation() {
    let isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;
    $('.jd-wizard .wizard-toggle-orientation').each(function () {
        let me = $(this);
        if (isMobile) {
            me.addClass('vertical');
            me.removeClass(me.data('default-orientation') || 'horizontal');
        } else {
            me.removeClass('vertical');
            me.addClass(me.data('default-orientation') || 'horizontal');
        }
    });
}

document.addEventListener('DOMContentLoaded', (event) => {
    Array.from(document.querySelectorAll('.media-download-modal'))
        .forEach((element, index) => {
            initMediaWizard(element);
        });
});

$(document).ready(function () {
    if (window.location.hash) {
        let hash = window.location.hash,
            element = $('[data-target="' + hash + '"]');
        if (element.length > 0) {
            window.jordanb2b.util.removeUrlHash();
            element.trigger('click');
        }
    }
    toggleWizardOrientation();
}).on('click', '.btn-media-download[data-target]', function (event) {
    let me = $(this);
    if (false === me.checkLoginModal(event, function (modal) {
        let input = modal.find('.modal-form input[name="redirect"]');
        if (input.length > 0) {
            let url = new URL(input.val());
            if (!url.hash) {
                url.hash = $(event.currentTarget).data('target');
                input.val(url.toString());
            }
        }
    })) {
        return false;
    }
    $(me.data('target')).modal('show');
});

$(window).on('resize', function () {
    toggleWizardOrientation();
});
$(document).ready(function () {

    if (!$('div#errorMsg').is(":visible")) {
        $('div#loginContent').hide();
    }

    if ($('div#errorMsg').length > 0) {
        $.handleLoginArea();
    }

    //gridstack
    if ($('.shopdesigner-widget').length > 0) {
        $('.shopdesigner-widget').checkForGridStackConfigsAndReplace();
    }
    $('.grid-stack').gridstack(jordanb2b.Settings.GridStackOptions)
        .on('change', function (event, items) {
            var img = event.target.getElementsByClassName('singleAD');
            if (img.length > 0) {
                $(img).each(function (index, imgElement) {
                    $(imgElement).width($(imgElement).parent().parent().width());
                    $(imgElement).height($(imgElement).parent().parent().height());
                });
            }
        });
    if ($('.grid-stack-item-loader').length > 0) {
        $('.grid-stack-item-loader').remove();
    }
    $('.grid-stack-item', '.grid-stack').css('visibility', 'visible');

    if ($('.nav-tabs-first').length > 0 && $('.nav-tabs-first').find('li.active').length <= 0) {
        $('.nav-tabs-first a:first').tab('show');
    }

    $.initPasswordStrength();

    var form = $('form');
    if (form.hasClass('file-upload-form')) {
        form.initFileUpload();
        form.initFilePond();
    }

    if ($(window).width() < 768) {
        //mobile collapse swiper
        $('#favoritemenu-container,#cartmenu-container', '.mobile-menu-container').on('shown.bs.collapse', function () {
            window.jordanb2b.util.initSwiper();
        });

        var collapsegroup = $('.mobile-menu-container');
        collapsegroup.on('show.bs.collapse', '.collapse', function () {
            collapsegroup.find('.collapse.in').collapse('hide');
        });
    }
}).on('click', '[data-href]', function (event) {
    event.preventDefault();
    var url = $(this).data('href');
    location.replace(url);
}).on('click', 'a#loginCall, button#hideCredContainer', function (event) {
    event.preventDefault();
    $.handleLoginArea();
}).on('click', '#info_customerLogin_', function () {
    $('#info_customerLogin_modal').modal('show');
}).on('click', '#info_password_', function () {
    $('#info_password_modal').modal('show');
}).on('shown.bs.tab', '.tab-switch-slick', function (e) {
    $('.productslider:visible:not(".slick-initialized")', $($(this).attr('href'))).slick({
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 970,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            }
        ]
    });
});
//modal trigger callback executable
var callbackExecutable = false;
//calbacks @see https://api.jquery.com/jQuery.Callbacks/
var callbacks = $.Callbacks('unique stopOnFalse');
//timeout modal
var timeoutModalObject = null;
//global configuration for the spinner
var spinjsopts = {
    lines: 11, // The number of lines to draw
    length: 3, // The length of each line
    width: 2, // The line thickness
    radius: 4, // The radius of the inner circle
    scale: 1, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    color: '#fff', // #rgb or #rrggbb or array of colors
    opacity: 0.25, // Opacity of the lines
    rotate: 0, // The rotation offset
    direction: 1, // 1: clockwise, -1: counterclockwise
    speed: 1, // Rounds per second
    trail: 60, // Afterglow percentage
    fps: 20, // Frames per second when using setTimeout() as a fallback for CSS
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    className: 'spinner', // The CSS class to assign to the spinner
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: false, // Whether to render a shadow
    hwaccel: false, // Whether to use hardware acceleration
    position: 'relative' // Element positioning
};

const toggleOnChange = ($, selector) => {
    const elements = typeof selector === 'undefined' ? $('[data-toggle="show-on-change"]') : $('[data-toggle="show-on-change"]', selector);

    elements.filter(':not(.binded)').each(function () {
        const me = $(this);
        me.addClass('binded');
        const value = me.data('value');
        const shouldDisableInputs = me.data('disable') === '';

        const input = $('[name="' + me.data('on') + '"]', selector || null);

        if (shouldDisableInputs) {
            if (me.hasClass('hidden')) {
                me.find('input, select, textarea').attr('disabled', 'disabled');
            } else {
                me.find('input, select, textarea').removeAttr('disabled');
            }
        }

        input.change(function () {
            const el = $(this);

            let show = false;

            if (el.attr('type') === 'checkbox') {
                show = el.is(':checked') === !!value;
            } else if (Array.isArray(value)) {
                show = !!value.find(testValue => String($(this).val()) === String(testValue))
            } else {
                show = String($(this).val()) === String(value);
            }

            me.toggleClass('hidden', !show);

            if (shouldDisableInputs) {
                if (show) {
                    me.find('input, select, textarea').removeAttr('disabled');
                } else {
                    me.find('input, select, textarea').attr('disabled', 'disabled');
                }
            }
        });
    });
}

(function ($) {

    var currentCalculatorRow;

    toggleOnChange($);

    /**
     * Loads a template for a modal
     * @param evt event object
     * @param href the link to the modal template
     * @param callback defines a function call what has to do after the modal is load
     * @returns object modal {*|jQuery}
     */
    $.loadTemplateForModal = function (evt, href, callback) {
        evt.preventDefault();
        var modalContainer = $('#modal-container'),
            modal = modalContainer.modal();

        modalContainer.on('hidden.bs.modal', function (e) {
            $('.modal-dialog', modalContainer).addClass('modal-md');
            $(this).removeData('bs.modal').find('.modal-content').html('');
            if (true === callbackExecutable) {
                callbacks.fire('modal.hidden');
            }
        }).on('shown.bs.modal', function (e) {
            jordanb2b.util.updateFormStyles(modal);
        });

        modal.find('.modal-content').load(href, function (responseText, textStatus) {
            if (textStatus === 'success' || textStatus === 'notmodified') {
                jordanb2b.util.updateFormStyles(modal);
                if (callback && typeof (callback) === 'function') {
                    callback(modal);
                }
            } else {
                modal.hide();
                modalContainer.modal('hide');
            }
        });
        return modal;
    };

    $.loadModal = function (event, uri, title, callback) {
        $.loadTemplateForModal(event, uri, function (modal) {
            if (title) {
                modal.find('.modal-title').html(title);
            }
            if (callback && typeof (callback) === 'function') {
                callback(modal);
            }
        });
    };

    $.loadModalContent = function (evt, content, timeout, size, withTemplate, title, callback) {
        if (evt) {
            evt.preventDefault();
        }

        if (typeof timeout === "undefined") {
            timeout = 0;
        }

        if (typeof size === "undefined") {
            size = 'lg';
        }

        if (typeof withTemplate === "undefined") {
            withTemplate = false;
        }

        if (true === withTemplate) {
            var template = '<div class="modal-header">' +
                '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
                '<h4 class="modal-title">##title##</h4>' +
                '</div>' +
                '<div class="modal-body">##body##</div>';
            content = template.replace('##title##', title).replace('##body##', content);
        }

        var modalContainer = $('#modal-container'),
            modal = modalContainer.modal();
        //return padding to 0
        $('body').get()[0].style.setProperty('padding-right', "0px");
        //modal size
        $('.modal-dialog', modalContainer).removeClass(function (index, className) {
            return (className.match(/(^|\s)modal-\S+/g) || []).join(' ');
        }).addClass('modal-dialog modal-' + size);

        var setModalTimeout = function (modal, timeout) {
            if (timeout > 0) {
                if (null !== timeoutModalObject) {
                    window.clearTimeout(timeoutModalObject);
                }
                timeoutModalObject = window.setTimeout(function () {
                    modal.modal('hide');
                }, timeout);
            } else {
                clearTimeout(timeoutModalObject);
            }
        };

        if (modalContainer.hasClass('in')) {
            //modal already shown -> set timeout
            setModalTimeout(modal, timeout);
        }

        modalContainer.on('hidden.bs.modal', function (e) {
            if (null !== timeoutModalObject) {
                clearTimeout(timeoutModalObject);
            }
            $(this).removeData('bs.modal').find('.modal-content').html('');
        }).on('shown.bs.modal', function (e) {
            jordanb2b.util.updateFormStyles(modal);
            setModalTimeout(modal, timeout);
            if (callback && typeof (callback) === 'function') {
                callback(modal);
            }
        });

        modal.find('.modal-content').html(content);
        return modal;
    };

    $.configDatepicker = function () {
        //disabling past date from datepicker
        var nowDate = new Date();
        var today = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0);
        //configure the datepicker
        $('input[name=dateselector],input.dateselector').datepicker({
            format: "dd.mm.yyyy",
            startDate: today,
            weekStart: 1,
            language: "de",
            daysOfWeekDisabled: "0,6",
            daysOfWeekHighlighted: "0,6",
            todayHighlight: true,
            autoclose: true
        });
    };

    //init product calculator
    $.initCalculator = function (container, callback) {
        if (typeof (container) === 'undefined') {
            container = $('.form-calculation');
        }
        var selectors = 'input[type!="hidden"]';
        container.on('blur focus', selectors, function (event) {
            if ($.active > 0 || 'readonly' === $(this).attr('readonly')) {
                return false;
            }
        }).on('keydown', selectors, function (event) {
            if ($.active > 0 || 'readonly' === $(this).attr('readonly')) {
                return false;
            }
            if (event.which === 13) {
                if ($(this).val().trim()) {
                    $(this).fetchPrices(false, callback);
                }
                return false;
            }
        }).on('keyup', selectors, function (event) {
            if (/[^0-9\.,]+/g.test(this.value)) {
                // Filter non-digits from input value.
                this.value = this.value.replace(/[^0-9\.,]+/g, '');
            }
        }).on('keypress', '.onlyInt', function (event) {
            if (event.key === ',' || event.key === '.') {
                event.preventDefault();
            }
        });

        $(selectors).donetyping(function () {
            if ($.active > 0 || 'readonly' === $(this).attr('readonly')) {
                return false;
            }
            if ($(this).val().trim()) {
                $(this).fetchPrices(false, function (response) {
                    $.checkSelectableProductsDiscountPromotion();
                    return true;
                });
            }
            return false;
        });

        $(container).find('select.onlyInts').donetyping(function () {
            if ($.active > 0 || 'readonly' === $(this).attr('readonly')) {
                return false;
            }
            if ($(this).val().trim()) {
                $(this).fetchPrices(false, callback);
            }
            return false;
        });

        if ($('input[type!="hidden"]:first', container).length > 0) {
            $('input[type!="hidden"]:first', container).each(function () {
                var calculationContainer = $(this).closest('.form-calculation');
                if ($(this).val().trim() && (!calculationContainer.hasClass('cart-update') || calculationContainer.hasClass('force-price-update'))) {
                    $(this).fetchPrices(true, callback);
                    $(this).fetchStockInfo(true);
                }
            });
        }
    };

    $.checkSelectableProductsDiscountPromotion = function (event) {
        $.ajax({
            url: '/dejordan/ajax/checkSelectableProductsDiscountPromotion',
            type: 'GET',
            async: true,
            success: function (response) {
                if (response.reload) {
                    window.location.reload();
                }
            }
        });
    }

    $.cartLayer = function (event) {
        $('header .jd-action-item-cart').fetchCart(event);
    };

    $.bookmarkLayer = function (event) {
        $('header .jd-action-item-bookmark').fetchBookmarks(event);
    };

    $.favoriteLayer = function (event) {
        $('header .jd-action-item-favorite').fetchFavorites(event);
    };

    $.urlParam = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results === null) {
            return null;
        } else {
            return results[1] || 0;
        }
    };

    $.buildFilterUri = function (uri) {

        if (typeof uri === "undefined") {
            uri = window.location.href;
        }

        var pageElement = $('.view-options ul.pagination li.active a'),
            typeElement = $('.view-options .view-as-list,.view-options .view-as-gallery'),
            sortElement = $('.view-options select.change-sort'),
            groupVariantsElement = $('.view-options .checkbox input[name="group-variants"]'),
            stockElement = $('.view-options .checkbox input[name="stock-items"]');

        var obj = URI(uri);
        //reset query
        obj.search("");

        if (pageElement) {
            obj.addQuery('page', pageElement.data('page'));
        } else {
            obj.removeQuery('page');
        }

        if (sortElement && sortElement.val()) {
            obj.addQuery('sort', sortElement.val());
        } else {
            obj.removeQuery('sort');
        }

        if (groupVariantsElement) {
            obj.addQuery('group', groupVariantsElement.is(':checked') ? 1 : 0);
        } else {
            obj.removeQuery('group');
        }

        if (stockElement) {
            obj.addQuery('stock-items', stockElement.is(':checked') ? 1 : 0);
        } else {
            obj.removeQuery('stock-items');
        }

        obj.removeQuery('attributes');
        $('select.attribute-filter.selected').not('.attribute-filter-mobile').each(function () {
            obj.addQuery('attributes[' + $("option:selected", $(this)).data('attribute') + '][value]', $("option:selected", $(this)).val());
        });
        $('input.form-slider.selected').each(function () {
            var me = $(this);
            obj.addQuery('attributes[' + me.data('attribute') + '][value]', me.val());
        });

        if (window.jordanb2b.util.getIEVersion() === 0 || window.jordanb2b.util.getIEVersion() > 10) {
            var baseHash = window.btoa(obj.query());
            //reset query
            obj.search("");
            obj.addQuery('base', baseHash);
        }

        window.jordanb2b.util.changeUrl($(document).attr('title'), obj.toString());
    };

    /* preload images */
    $.preloadImages = function (images) {
        for (var i = 0; i < images.length; i++) {
            $("<img />").attr("src", images[i]);
        }
    };

    $.putProductToCart = function (event, data, url, reload, dataLayer) {

        if (dataLayer) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(dataLayer);
        }

        if (undefined === url || null === url) {
            url = '/checkout';
        }
        if (undefined === reload) {
            reload = $('.checkout-container').length > 0 ? true : false;
        }

        var target = $(event.currentTarget);
        if (target.length > 0) {
            target.spinnerButton('start');
        }

        $('.product-error-container').addClass('hidden');

        $.ajax({
            'url': url,
            'type': 'POST',
            'data': data,
            'async': true,
            'complete': function (data, textStatus) {
                var res = data.responseText.trim().replace(/["']/g, "");
                if (textStatus === "success" && "true" === res) {
                    if (true === reload) {
                        var obj = URI(window.location.href);
                        obj.addQuery('item', 'added');
                        window.location.href = obj.toString();
                    } else {
                        $.cartLayer(event);
                    }
                } else {
                    if (data.responseJSON) {
                        $.each(data.responseJSON, function (index, value) {
                            var errorContainer = $('.product-error-container-' + index);
                            if (errorContainer.length > 0) {
                                $('.alert', errorContainer).html(value);
                                errorContainer.removeClass('hidden');
                            }
                        });
                    }
                }
                if (target.length > 0) {
                    target.spinnerButton('stop');
                }
            }
        });
    };

    $.downloadFile = function (uri, data, modalSelector) {
        let modal = modalSelector && $(modalSelector).length > 0 ? $(modalSelector) : null;

        $.fileDownload(uri, {
            httpMethod: (undefined !== data && !$.isEmptyObject(data)) ? 'POST' : 'GET',
            data: (undefined !== data && !$.isEmptyObject(data)) ? data : {},
            prepareCallback: function () {
                //prepareCallback
                if (modal) {
                    modal.find('.alert').addClass('hidden');
                    modal.find('.modal-footer').addClass('hidden');
                    modal.find('.alert-prepare').removeClass('hidden');
                    modal.modal('show');
                }
            },
            successCallback: function (url) {
                //successCallback
                if (modal) {
                    modal.modal('hide');
                    modal.find('.modal-footer').addClass('hidden');
                    modal.find('.alert').addClass('hidden');
                }
            },
            failCallback: function (responseHtml, url) {
                //failCallback
                if (modal) {
                    modal.find('.alert').addClass('hidden');
                    modal.find('.modal-footer').removeClass('hidden');
                    modal.find('.alert-failure').removeClass('hidden');
                }
            }
        });
    };

    $.handleLoginArea = function () {
        var content = $('div#loginContent');
        var loginContainer = $('#jd-login-container');
        var triangledown = $('.triangle-down');
        var ipadFix = function (dir) {
            var stickyContainer = $('.basic-sticky-example');
            if (stickyContainer.parent().prop('tagName') == 'BODY') {
                var h = parseInt(stickyContainer.css('top'));
                var k = $('#jd-login-container').height();
                if (h) {
                    if (dir == 'show') {
                        stickyContainer.animate({top: (h + k) + 'px'});
                    } else {
                        stickyContainer.animate({top: (h - k) + 'px'});
                    }
                }
            }
        };
        if (content.is(":visible")) {
            ipadFix('hide');
            content.slideToggle('slow').promise().done(function () {
                loginContainer.removeClass('jd-border-bottom');
                triangledown.hide();
                loginContainer.hide();
            });
            // fix for ipad
        } else {
            loginContainer.show();
            content.slideToggle('slow');
            loginContainer.addClass('jd-border-bottom');
            triangledown.show();
            ipadFix('show');
        }
    };

    $.validateFormElements = function (form, identification) {
        var fieldsValid = true;

        $('button.add-to-cart', form).removeClass('disabled');
        $('button.add-to-cart', form).removeAttr('disabled');

        for (var i = 0; i < 3; i++) {
            if (false === fieldsValid) {
                continue;
            }
            var formElement = $('.calculator-' + identification + ' input[name="amounts[' + i + ']"]');
            if (formElement.length > 0) {
                var maxValue = formElement.data('max');
                if (maxValue) {
                    var myValue = parseInt(formElement.val().replace(",", "."), 10);
                    var myMax = parseInt(maxValue, 10);
                    if (!$.isNumeric(myValue)) {
                        fieldsValid = false;
                    } else {
                        if (myValue > myMax) {
                            if (form) {
                                $('button.add-to-cart', form).addClass('disabled');
                                $('button.add-to-cart', form).attr('disabled', 'disabled');
                                //$('.bookmark-product-to-cart[data-product-id="' + identification + '"]').addClass('disabled');
                                //$('.bookmark-product-to-cart[data-product-id="' + identification + '"]').attr('disabled', 'disabled');
                            }
                            fieldsValid = false;
                        }
                    }
                }
            }
        }

        return fieldsValid;
    };

    $.initPasswordStrength = function () {
        //translations @see js-helper.tpl
        var options = {
            common: {
                minChar: jordanb2b.Settings.password.minLength,
            },
            rules: {},
            ui: {
                bootstrap3: true,
                showVerdictsInsideProgressBar: true
            }
        };
        $('#password').pwstrength(options);
    };

    $.checkDeliverydate = function (status) {
        if (status) {
            //disable deliveryDate
            $('#deliveryDate').val('');
            $('#deliveryDate').attr('disabled', 'disabled');
        } else {
            //enable deliveryDate
            $('#deliveryDate').removeAttr('disabled');
        }
    };
    $.checkCartModifications = function (interval) {
        if (jordanb2b.Settings.Customer.isLoggedIn && (interval > 0 || jordanb2b.Settings.cartCheckInterval > 0)) {
            setInterval(function () {
                $.ajax({
                    url: '/dejordan/ajax/check-cart',
                    type: 'GET',
                    async: true,
                    success: function (response) {
                        if (response.changed) {
                            if (response.redirect) {
                                window.location.href = response.redirect;
                            } else {
                                window.location.reload(true);
                            }
                        }
                    }
                });
            }, interval || jordanb2b.Settings.cartCheckInterval);
        }
    };

    $.getDataForPriceAndStockRequest = function (elem) {
        var productId = $(elem).data('product');
        if (!productId || $('.form-calculation-' + productId).hasClass('cart-overview')) {
            return false;
        }

        var identification;
        if ($(elem).data('identification')) {
            identification = $(elem).data('identification');
        } else {
            identification = $(elem).data('product');
        }

        var container = $(elem).parents('.calculator-' + identification),
            amounts = [],
            amountsValid = false,
            amountsPresents = false,
            key = 0;

        $('input[name^="amounts["], select[name^="amounts["]', container).each(function (index) {
            if (undefined === identification || ($(this).data('identification') != identification && $(this).data('product') != identification)) {
                return true;
            }

            var val;
            if ($(this).is('select')) {
                val = $('option:selected', $(this)).text();
            } else {
                val = $(this).val().replace(",", ".");
            }

            amounts[key] = parseFloat(val);
            if (amounts[key] > 0 && (amountsValid || key === 0) && !isNaN(amounts[key]) && (!$(this).hasClass('onlyInt') || val % 1 === 0)) {
                amountsValid = true;
            } else if (amounts[key] <= 0 || isNaN(amounts[key]) || ($(this).hasClass('onlyInt') && val % 1 !== 0)) {
                amountsValid = false;
            }
            amountsPresents = true;
            key++;
        });

        const volume = $('input[name^="product[' + productId + '][quantity]"]', container);
        const volumeVal = parseFloat($(volume).val().replace(",", "."));

        if ((!amountsValid && amountsPresents) || volumeVal <= 0 || isNaN(volumeVal) || ($(volume).hasClass('onlyInt') && volumeVal % 1 !== 0)) {
            return false;
        }

        return {
            container: container,
            productId: productId,
            identification: identification,
            amounts: amounts,
            volume: volume,
            volumeVal: volumeVal,
            amountsValid: amountsValid,
            amountsPresents: amountsPresents,
        };
    }

    $.fn.extend({
        donetyping: function (callback, timeout) {
            timeout = timeout || 1000; // 1000ms default timeout
            var timeoutReference,
                doneTyping = function (el) {
                    if (!timeoutReference) return;
                    timeoutReference = null;
                    callback.call(el);
                };
            return this.each(function (i, el) {
                var $el = $(el);
                // Chrome Fix (Use keyup over keypress to detect backspace)
                // thank you @palerdot
                $el.is(':input') && $el.on('keyup keypress', function (e) {
                    // This catches the backspace button in chrome, but also prevents
                    // the event from triggering too premptively. Without this line,
                    // using tab/shift+tab will make the focused element fire the callback.
                    if (e.type == 'keyup' && e.keyCode != 8) return;

                    // Check if timeout has been set. If it has, "reset" the clock and
                    // start over again.
                    if (timeoutReference) clearTimeout(timeoutReference);
                    timeoutReference = setTimeout(function () {
                        // if we made it here, our timeout has elapsed. Fire the
                        // callback
                        doneTyping(el);
                    }, timeout);
                }).on('blur', function () {
                    // If we can, fire the event since we're leaving the field
                    doneTyping(el);
                });
                $el.is('select') && $el.on('change', function () {
                    if (timeoutReference) clearTimeout(timeoutReference);
                    timeoutReference = setTimeout(function () {
                        // if we made it here, our timeout has elapsed. Fire the
                        // callback
                        doneTyping(el);
                    }, timeout);
                });
            });
        },

        fetchStockInfo: function (includeVariants) {
            return this.each(function (i, elem) {
                const dataForElement = $.getDataForPriceAndStockRequest(elem);

                if (dataForElement) {
                    $.ajax({
                        url: '/dejordan/ajax/yourPrice',
                        data: {
                            productId: dataForElement.productId,
                            amounts: dataForElement.amounts,
                            volume: dataForElement.volumeVal,
                            identification: dataForElement.identification,
                            cartUpdate: false,
                            stockinfo: true,
                            centralStockInfo: true,
                            includeVariants: includeVariants || false
                        },
                        type: 'GET',
                        async: true,
                        success: function (response) {
                            if (response.error) {
                                return false;
                            }

                            if ($(dataForElement.container).length > 0) {
                                $(dataForElement.container).updateStockInfo(response);
                            }
                        }
                    });
                }
            });
        },
        fetchPrices: function (stockinfo, callback) {
            return this.each(function (i, elem) {
                $(elem).removeClass('error');
                $(elem).closest('.form-group').removeClass('has-error');

                const dataForElement = $.getDataForPriceAndStockRequest(elem);

                if (dataForElement) {
                    if ((!dataForElement.amountsValid && dataForElement.amountsPresents) || dataForElement.volumeVal <= 0 || isNaN(dataForElement.volumeVal) || ($(dataForElement.volume).hasClass('onlyInt') && dataForElement.volumeVal % 1 !== 0)) {
                        $('#noAmount').removeClass('hidden');
                        $(elem).addClass('error');
                        return false;
                    } else {
                        $(elem).parent().parent().find('input').removeClass('error');
                        $('#noAmount').addClass('hidden');
                    }

                    var cartUpdate = $('.calculator-' + dataForElement.identification, dataForElement.container).hasClass('cart-update') || dataForElement.container.hasClass('cart-update');

                    // disable buttons
                    $('.disable-fetch-price', dataForElement.container).not('.disabled').attr('disabled', 'disabled').prop('disabled', true);
                    $('.disable-fetch-price-global').not('.disabled').attr('disabled', 'disabled').prop('disabled', true);
                    $('.disable-fetch-price-group').not('.disabled').attr('disabled', 'disabled').prop('disabled', true);
                    $('.disable-fetch-price-' + dataForElement.identification).not('.disabled').attr('disabled', 'disabled').prop('disabled', true);

                    // calculate prices
                    $.ajax({
                        url: '/dejordan/ajax/yourPrice',
                        data: {
                            productId: dataForElement.productId,
                            amounts: dataForElement.amounts,
                            volume: dataForElement.volumeVal,
                            identification: dataForElement.identification,
                            cartUpdate: cartUpdate,
                            stockinfo: stockinfo || false,
                            centralStockInfo: stockinfo || false,
                            includeVariants: false
                        },
                        type: 'GET',
                        async: true,
                        success: function (response) {
                            if (response.error) {
                                var home = window.location.href.substr(0, window.location.href.indexOf('/', 10));
                                window.location.href = home;
                                return;
                            }
                            if (response.reload) {
                                window.location.reload();
                            }
                            if ($(dataForElement.container).length > 0) {
                                $(dataForElement.container).updateCalculator(response, dataForElement.identification);
                                // TODO merge: check if both are required
                                $(dataForElement.container).updateCentralStockWidget(response, dataForElement.identification);
                                $(dataForElement.container).updateStockInfo(response);
                            }

                            if (callback && typeof (callback) === 'function') {
                                callback.call(this, response);
                            }
                        }
                    }).always(function () {
                        //enable buttons
                        $('.disable-fetch-price', dataForElement.container).not('.disabled').removeAttr('disabled').prop('disabled', false);
                        $('.disable-fetch-price-global').not('.disabled').removeAttr('disabled').prop('disabled', false);
                        $('.disable-fetch-price-' + dataForElement.identification).not('.disabled').removeAttr('disabled').prop('disabled', false);
                        $('.disable-fetch-price-group').each(function (index, value) {
                            //check
                            var me = $(this),
                                groupContainer = $(me.data('group-container')),
                                valid = true;
                            if (groupContainer.length > 0) {
                                $('.disable-fetch-price', groupContainer).each(function () {
                                    if ($(this).prop('disabled')) {
                                        valid = false;
                                        return true;
                                    }
                                });
                                if (valid) {
                                    me.removeAttr('disabled').prop('disabled', false);
                                }
                            }
                        });
                    });

                    return false;
                }
            })
        },
        /**
         * UI update of the widget showing the current central
         * stock. Update is triggered by ajax request.
         *
         * The UI will be updated to show current status and
         * stock (if available).
         *
         * @param response
         * @param identification
         */
        updateCentralStockWidget: function (response, identification) {
            if (typeof identification === 'undefined') {
                identification = response.productId;
            }

            var form = $('.form-calculation-' + identification);
            var centralStockWidgetContainer = $('.central-stock-widget-container', form);
            var centralStockWidgetIconContainer = $('.icon-container', centralStockWidgetContainer);
            var centralStockWidgetTextContainer = $('.text-container', centralStockWidgetContainer);
            var centralStockWidgetAdditionalTextContainer = $('.additional-text-container', centralStockWidgetContainer);

            /**
             * Show icon and related text to indicate central stock. Executing this method
             * will hide all other variants.
             *
             * @param responseType valid values are (ok|warning|critical|empty|undefined)
             */
            function showCentralStockResponse(responseType) {
                var containers = [centralStockWidgetIconContainer, centralStockWidgetTextContainer];
                containers.forEach(function (container) {
                    container.find('span').each(function () {
                        if ($(this).data('type') !== responseType) {
                            $(this).addClass('hidden');
                        } else {
                            $(this).removeClass('hidden');
                        }
                    });
                });
            }

            for (var productId in response.centralStockInfo) {
                if (identification == response.centralStockInfo[productId].id) {
                    var centralStockInfoResponseForProduct = response.centralStockInfo[productId];
                    showCentralStockResponse(centralStockInfoResponseForProduct.status);
                    if (centralStockInfoResponseForProduct.quantity) {
                        centralStockWidgetAdditionalTextContainer.html(centralStockInfoResponseForProduct.quantity + " " + response.packagingUnit);
                    }

                }
            }

        },
        updateCalculator: function (response, identification) {
            if (typeof identification === 'undefined') {
                identification = response.productId;
            }

            var form = $('.form-calculation-' + identification);
            var priceDefaultContainer = $('.price-default-container', form);
            var priceCustomerContainer = $('.price-customer-container', form);
            var tieredPricesContainer = $('.tiered-prices-container', form);
            var tieredPricesCartContainer = $('.tiered-prices-cart-container', form);

            if (true === response.error) {
                $('.price-customer-not-available .alert', form).html(response.message);
                $('.price-customer-not-available', form).removeClass('hidden');
                return false;
            } else {
                $('.price-customer-not-available', form).addClass('hidden');
            }

            if (true === response.customerPrice) {
                priceDefaultContainer.removeClass('col-sm-offset-9');
                $('.price-default', priceDefaultContainer).html(jordanb2b.Price.FormatLimit(response.standardPriceGross, 2) + (response.packagingUnit ? ' / ' + response.packagingUnit : ''));
                $('.price-customer-not-available', form).addClass('hidden');
                priceCustomerContainer.removeClass('hidden');

                if (response.showTieredPrices) {
                    $('.form-calculation-' + identification + ' .price-customer-container .price-customer').html(jordanb2b.Price.FormatLimit(response.price, 2) + (response.packagingUnit ? ' / ' + response.packagingUnit : ''));
                    tieredPricesContainer.html(response.tieredPricesHtml);
                    tieredPricesCartContainer.html(response.tieredPricesCartHtml);
                } else {
                    tieredPricesContainer.html(response.noTieredPricesHtml);
                    if (response.priceStrikethrough) {
                        $('.form-calculation-' + identification + ' .price-customer-container .price-customer-strikethrough').html(jordanb2b.Price.FormatLimit(response.priceStrikethrough, 2) + (response.packagingUnit ? ' / ' + response.packagingUnit : ''));
                    }

                    $('.form-calculation-' + identification + ' .price-customer-container .price-customer').html(jordanb2b.Price.FormatLimit(response.price, 2) + (response.packagingUnit ? ' / ' + response.packagingUnit : ''));
                    tieredPricesCartContainer.html('');
                }
            } else if (true === response.specialPrice) {
                if (response.customerPriceValue) {
                    $('.special-svk1', form).html(jordanb2b.Price.FormatLimit(response.customerPriceValue, 2) + (response.packagingUnit ? ' / ' + response.packagingUnit : ''));
                }
                if (response.promotionPrice) {
                    priceCustomerContainer.removeClass('hidden');
                }
                if (!jordanb2b.Settings.Customer.isLoggedIn && true !== response.disableCustomerPrices) {
                    $('.price-customer-not-available', form).removeClass('hidden');
                }
            } else {
                priceCustomerContainer.addClass('hidden');
                if (true !== response.disableCustomerPrices) {
                    $('.price-customer-not-available', form).removeClass('hidden');
                    priceDefaultContainer.removeClass('col-sm-offset-9');
                }
            }
            priceDefaultContainer.removeClass('hidden');

            var $priceTotal = $('.price-total', this);
            if ($priceTotal.length === 0) {
                $priceTotal = $('.price-total', form);
            }
            if (jordanb2b.Settings.Customer.isLoggedIn && false === response.presentationMode) {
                $priceTotal.html(jordanb2b.Price.FormatLimit(response.total, 2));
                $('.price-type-info', this).html(window.jordanb2b.Dictionary.Product.purchasePrice);
            } else {
                $priceTotal.html(jordanb2b.Price.FormatLimit(response.totalGross, 2));
                $('.price-type-info', this).html(window.jordanb2b.Dictionary.Product.recommendedSalesPrice);
            }
            $cart = $('.form-calculation-' + identification + '.cart-update');
            if ($cart.length > 0) {
                if (jordanb2b.Settings.Customer.isLoggedIn && false === response.presentationMode) {
                    $cart.find('.price-total').html(jordanb2b.Price.FormatLimit(response.total, 2));
                } else {
                    $cart.find('.price-total').html(jordanb2b.Price.FormatLimit(response.totalGross, 2));
                }
            }
            if ($('#accordion-shopping-list').length > 0) {
                var total = 0;
                $priceTotal.parents('.shopping-list-body').find('.price-info.price-total').each(function (i, e) {
                    var current = parseFloat($(e).html().replace('.', '').replace(',', '.').replace(/[^\d\.]/g, ''));
                    if (isNaN(current) === false) {
                        total = total + current;
                    }
                });
                total = (Math.round(total / 2 * 100) / 100);
                var totalString = '';
                if (total > 1000) {
                    var t = Math.floor(total / 1000).toString();
                    totalString = t + '.' + total.toString().substr(t.length).replace('.', ',');
                } else {
                    totalString += total.toString().replace('.', ',');
                }
                if (totalString.indexOf(',') == -1) {
                    totalString += ',00';
                } else if (totalString.substr(-2, 1) == ',') {
                    totalString += '0';
                } else if (totalString.substr(-1, 1) == ',') {
                    totalString += '00';
                }
                totalString += ' €';
                $priceTotal.parents('.shopping-list-body').find('.orderSummary h4').eq(1).html(totalString);
            }

            $('input[name^="product[' + identification + '][quantity]"]', this).val(response.volume);
            $('input[id="quantity"][data-identification="' + identification + '"]', this).val(response.volume);

            if (response.summary) {
                $('.cart-summary').html(response.summary);
            }

            return false;
        },
        /**
         * Updates product variant select element
         * with stock information for all products
         * in the response.
         * And the stock information on the product
         * detail page for currently viewed product.
         *
         * @param response
         */
        updateStockInfo: function (response) {
            // helper method to change the stock
            // icon for given variant option element
            function changeIcon($obj) {
                var stockinfo = $('.productvariant-selection option:selected', 'form.productvariantform').data('instock');
                var icon;
                if (stockinfo > 0) {
                    icon = '<i class="stockicon text-success fas fa-dolly"></i>';
                } else if (stockinfo === 0) {
                    icon = '<i class="stockicon text-success fas fa-warehouse-alt"></i>';
                } else if (stockinfo === -2) {
                    icon = '<i class="stockicon text-success far fa-truck-moving"></i>';
                }
                if (icon) {
                    $obj.find('i.stockicon').replaceWith(icon);
                }
            }

            if (response.stockinfo) {
                var variantSelection = $('form.productvariantform');

                for (var productId in response.stockinfo) {

                    // Update the stock information within the select
                    // dropdown for all variants
                    if (variantSelection.length > 0) {
                        var instock = (response.stockinfo[productId].instock > 0 || response.stockinfo[productId].area.length > 0) ? 1 : 0;
                        if (response.stockinfo[productId].instock < -1) {
                            instock = response.stockinfo[productId].instock;
                        }
                        $('option[data-product="' + response.stockinfo[productId].id + '"]', variantSelection[0])
                            .data('instock', instock)
                            .data('expire', response.stockinfo[productId].articleOnExpire);
                        //show legend
                        $('.product-stock-legend').removeClass('hidden');
                    }

                    // Update stock info on product detail page
                    // for the currently viewed product
                    if (response.productId == response.stockinfo[productId].id) {
                        if (response.stockinfo[productId].instock > 0) {
                            $('.price-stockinfo .defaultstock').hide();
                            var $obj = $('.price-stockinfo .instock');
                            var stockVal = response.stockinfo[productId].instock.toString().replace('.', ',');
                            var decPos = stockVal.indexOf(',');
                            if (decPos > 3) {
                                stockVal = stockVal.substr(0, decPos - 3) + '.' + stockVal.substr(decPos - 3);
                            }
                            $obj.find('span.stockamount').html(stockVal);
                            if (response.volumeRaw > response.stockinfo[productId].instock) {
                                var icon = '<i class="stockicon text-success fas fa-warehouse-alt"></i>';
                                $obj.find('i.stockicon').replaceWith(icon);
                            } else {
                                changeIcon($obj);
                            }
                            $obj.removeClass('hidden');
                        } else if (response.stockinfo[productId].area.length > 0) {
                            $('.price-stockinfo .defaultstock').hide();
                            var $obj = $('.price-stockinfo .areastock');
                            $obj.each(function () {
                                var $select = $(this).find('select');
                                $select.find('option').remove();
                                var unit = $(this).find('.unit').first().text();
                                for (var i = 0; i < response.stockinfo[productId].area.length; i++) {
                                    var stockVal = response.stockinfo[productId].area[i].stock.toString().replace('.', ',');
                                    var decPos = stockVal.indexOf(',');
                                    if (decPos > 3) {
                                        stockVal = stockVal.substr(0, decPos - 3) + '.' + stockVal.substr(decPos - 3);
                                    }
                                    $select.append(
                                        '<option value="' + response.stockinfo[productId].area[i].ident + '">'
                                        + response.stockinfo[productId].area[i].name + ' / '
                                        + stockVal + ' '
                                        + unit + '</option>'
                                    );
                                }
                                changeIcon($(this));
                                $(this).removeClass('hidden');
                            });
                        } else {
                            changeIcon($('.price-stockinfo .defaultstock'));
                        }
                    }
                }
                if (variantSelection.length > 0) {
                    window.jordanb2b.util.initSelect2();
                }
            }
        },
        fetchAttributes: function (callback) {
            var attributes = {},
                url = '/dejordan/productFilter/attribute';
            this.each(function (i, elem) {
                var el = $(this);
                if (el.data('map')) {
                    url = el.data('map');
                }
                if (el.hasClass('selected')) {
                    var attributeId,
                        val;
                    if (el.hasClass('form-slider')) {
                        val = el.val();
                        attributeId = el.data('attribute');
                    } else {
                        attributeId = $("option:selected", $(this)).data('attribute');
                        val = $("option:selected", $(this)).val();
                    }
                    if (!attributeId) {
                        attributeId = el.data('attribute');
                    }
                    attributes[attributeId] = {value: val};
                }
            });

            if (this.length === 0 && $('#filter-url').length > 0) {
                url = $('#filter-url').val() + '/attribute';
            }

            $.ajax({
                url: url,
                data: {'attributes': attributes, 'content': 1},
                dataType: 'json',
                type: 'get',
                success: function (response) {
                    $('#content-container').updateProductFilterContent(response.content, response.onlyproducts);
                    if (callback && typeof (callback) === 'function') {
                        callback();
                    }

                    var selectedAttributes = window.jordanb2b.util.getSelectedFilterElements(false);
                    if (selectedAttributes.length > 0) {
                        $('#accordion-filter #collapse-filter .panel-footer').removeClass('hide');
                    } else {
                        $('#accordion-filter #collapse-filter .panel-footer').addClass('hide');
                    }
                }
            });
            return false;
        },
        initCalculateAmountForm: function (row) {
            var modal = $(this),
                form = modal.find('form[data-toggle="validator"]');

            if (typeof (row) != 'undefined') {
                calculatorCurrentRow = row;
            }
            $('[data-toggle="tooltip"]', modal).tooltip();

            form.validator({disable: false}).on('submit', function (e) {
                if (e.isDefaultPrevented()) {
                    // handle the invalid form...
                    return false;
                } else {
                    var length = $('#roomlength').val(),
                        width = $('#roomwidth').val(),
                        demand = $('#demand').val(),
                        identifier = $('#identifier', form).val(),
                        materialrequirement = $('#materialrequirement', form).val();

                    // check if eigher roomlength and width or demand is set
                    if ((isNaN(demand) || '' == demand) && ((isNaN(length) || '' == length) || isNaN(width) || '' == width)) {
                        return false;
                    }

                    // everything looks good!
                    // find the target container (the row that needs to be updated in the price box)
                    var targetContainer = $('.form-calculation-' + identifier + ' div.calculator[data-row="' + calculatorCurrentRow + '"]');
                    var calculationModel = $('input[name=calculator-type]').val();
                    var desiredAmount = 0;

                    if (targetContainer) {
                        var targetElement = targetContainer.find('input[name^="amounts"]:last');
                        if (calculationModel == 'TE') {
                            targetElement = targetContainer.find('input[name="product[' + identifier + '][quantity]"]');
                        }
                        if (targetElement.hasClass('onlyInt')) {
                            desiredAmount = parseInt(materialrequirement);
                        } else if (calculationModel == 'TE') {
                            desiredAmount = demand.replace('.', ',');
                        } else {
                            desiredAmount = materialrequirement.replace(".", ",");
                        }
                        targetElement.val(desiredAmount).fetchPrices();
                    }
                    $('#modal-container').modal('hide');
                }
                return false;
            }).on('valid.bs.validator', function (event) {
                if ($(event.detail).length > 0) {
                    form.find('#form-errors .alert').html('').addClass('hide');
                }
            }).on('invalid.bs.validator', function (event) {
                $(event.detail).each(function (index, value) {
                    var target = $(event.relatedTarget),
                        label = $('label[for="' + target.attr('id') + '"]');
                    form.find('#form-errors .alert').append('<p>' + value + '</p>').removeClass('hide');
                });
            });

            $('input', form).donetyping(function () {
                if ($('.has-error', form).length > 0) {
                    return false;
                }
                form.calculateAmount($(this));
                return false;
            });

            $('select', form).on('change', function () {
                if ($('.has-error', form).length > 0) {
                    return false;
                }
                form.calculateAmount();
                return false;
            });

            return false;
        },
        calculateAmount: function (elFocus) {
            var form = $(this),
                action = form.attr("action");

            if (elFocus) {
                var currId = elFocus.attr('id');
                if (currId == 'roomwidth' || currId == 'roomlength') {
                    form.find('#demand').val('');
                } else if (currId == 'demand') {
                    form.find('#roomwidth').val('');
                    form.find('#roomlength').val('');
                }
            }

            var length = $('#roomlength', form).val(),
                width = $('#roomwidth', form).val(),
                demand = $('#demand', form).val();

            if ((isNaN(demand) || '' == demand) && ((isNaN(length) || '' == length) || isNaN(width) || '' == width)) {
                return false;
            }

            $.ajax({
                'url': action,
                'type': 'POST',
                'data': form.serialize(),
                'async': true,
                'complete': function (data, textStatus) {
                    var response = data.responseText;
                    var modal = $('#modal-container .modal-content');
                    modal.html(response);
                    modal.initCalculateAmountForm();
                    return true;
                }
            });
            return false;
        },
        updateProductFilterContent: function (response, onlyproducts) {
            if (typeof onlyproducts === "undefined") {
                onlyproducts = false;
            }

            if (true === onlyproducts) {
                $(this).find('#startProducts').html(response);
                window.jordanb2b.util.scroll();
                window.jordanb2b.util.initAll();
            } else {
                $(this).html(response);
                window.jordanb2b.util.initAll();
            }
            $.buildFilterUri();
        },
        appendAttributeButtons: function (type) {
            var me = $(this);
            me.html('');
            var elements = window.jordanb2b.util.getSelectedFilterElements(false);
            if (elements.length > 0) {
                elements.each(function (i, element) {
                    var el = $(element),
                        //value = el.val(),
                        value = $("option:selected", $(element)).text(),
                        dataValue = $("option:selected", $(element)).data('value'),
                        id = el.attr('id').replace('-mobile', ''),
                        name = el.data('name') ? el.data('name') : $('label#label-' + id).html();

                    if (typeof dataValue !== "undefined") {
                        value = dataValue;
                    }

                    if ($('button#filter-' + id).length === 0 && value) {
                        if (el.hasClass('form-slider')) {
                            //slider - change value
                            value = value.split(',').join(' - ');
                        }
                        // replace html tags
                        value = value.replace('</li><li>', ' / ').replace(/<\/?[^>]+(>|$)/g, "");
                        var text = name + ": " + value + " " + "<i class=\"fas fa-times\" aria-hidden=\"true\"></i>";
                        $('<button/>')
                            .attr('id', 'filter-' + id)
                            .attr('data-remove', id)
                            .addClass('btn btn-primary btn-xs filter-button')
                            .html(text)
                            .appendTo(me);
                        me.append(' ');
                    } else if (!value) {
                        el.removeClass('selected').val('');
                    }
                });
                if ('show' == type) {
                    me.fadeIn("slow");
                } else if ('hide' == type) {
                    me.fadeOut("slow");
                }
            }
        },
        checkModalSize: function () {
            var modalSize = $(this).data('modalsize'),
                modalContainerDialog = $('#modal-container .modal-dialog');
            if (modalSize === "default") {
                modalContainerDialog.removeClass('modal-lg');
            } else {
                modalContainerDialog.addClass('modal-lg');
            }
        },
        addTriggerCallback: function (callbackType) {
            var me = $(this);
            var callbackFunction = function (callerType) {
                if (callbackType == callerType) {
                    callbacks.empty();
                    me.trigger('click');
                    return false;
                }
            };
            callbacks.add(callbackFunction);
        },
        sortSelect: function (skip_first) {
            var options = (skip_first) ? $('option:not(:first)', this) : $('option', this),
                arr = options.map(function (_, o) {
                    return {t: $(o).text(), v: o.value};
                }).get();
            arr.sort(function (o1, o2) {
                var t1 = o1.t.toLowerCase(), t2 = o2.t.toLowerCase();
                return t1 > t2 ? 1 : t1 < t2 ? -1 : 0;
            });
            options.each(function (i, o) {
                o.value = arr[i].v;
                $(o).text(arr[i].t);
                if (arr[i].s) {
                    $(o).attr('selected', 'selected').prop('selected', true);
                } else {
                    $(o).removeAttr('selected');
                    $(o).prop('selected', false);
                }
            });
        },
        fetchCart: function (event) {
            var me = $(this);
            var url = '/dejordan/ajax/shoppingIcon';
            if (typeof (TerraceCalculator) !== 'undefined' && TerraceCalculator.hasCalculation() === true) {
                url += '?hideToCart=1'
            }
            $.get(url, function (response) {
                if (response.template) {
                    //icon + mini cart
                    me.html(response.template);
                    window.jordanb2b.util.initSwiper();
                }
                if (response.info) {
                    //modal
                    $.loadModalContent(event, response.info, jordanb2b.Settings.Growl.life, 'md');
                }
            }, 'json');
        },
        fetchBookmarks: function (event) {
            var me = $(this);
            $.get('/dejordanbookmark/bookmark-layer', function (response) {
                if (response.template) {
                    me.html(response.template);
                    window.jordanb2b.util.initSwiper();
                }
            }, 'json');
        },
        fetchFavorites: function (event) {
            var me = $(this);
            $.get('/dejordanbookmark/favorits-layer', function (response) {
                if (response.template) {
                    me.html(response.template);
                    window.jordanb2b.util.initSwiper();
                }
            }, 'json');
        },
        toggleFilterButton: function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).find('i:not(.ignore)').remove();
            } else {
                $(this).addClass('active');
                $(this).append('<i class="fas fa-check-circle" aria-hidden="true"></i>');
            }
        },
        checkLoginModal: function (event, callback) {
            var me = $(this);
            if (!jordanb2b.Settings.Customer.isLoggedIn) {
                callbackExecutable = false;
                me.addTriggerCallback('modal.hidden');
                $.loadModal(event, '/customer/ajax-login', me.data('title'), callback);
                return false;
            }
            return true;
        },
        createSpinnerContainer: function () {
            if ($(this).find('.spinnerContainer').length <= 0) {
                $(this).prepend('<span class="spinnerContainer hidden"></span>');
            }
        },
        spinnerButton: function (type) {
            if (typeof type === "undefined") {
                type = 'start';
            }
            var spinnertarget = $('.spinnerContainer', $(this));
            if (spinnertarget.length > 0) {
                if ('start' === type) {
                    spinnertarget.spin(spinjsopts).removeClass('hidden');
                    $(this).attr('disabled', 'disabled');
                } else if ('stop' === type) {
                    spinnertarget.spin(false).addClass('hidden');
                    $(this).removeAttr('disabled', 'disabled');
                }
            }
        },
        blockingOverlay: function () {
            if ($(this).find('.page-overlay').length === 0) {
                $(this).prepend('<div class="page-overlay"><div class="overlay-container"><div class="overlay-loader"></div><p class="overlay-text">' + jordanb2b.Dictionary.Checkout.submit + '</p></div></div>');
                $(this).find('.overlay-loader').spin(Object.assign({}, spinjsopts, {scale: 3}));
            }
        },
        submitForm: function (isModal, disable) {
            if (typeof isModal === "undefined") {
                isModal = false;
            }
            if (typeof disable === "undefined") {
                disable = false;
            }
            var form = $(this),
                action = form.attr('action'),
                submit = form.find('input[type="submit"],button[type="submit"]'),
                target = form.data('target');

            if (true === isModal) {
                var title = $('#modal-container .modal-content .modal-title').html();
            }

            if (disable === true) {
                var $inputFields = $('input,select', form);
                $inputFields.prop('readonly', true);
            }

            if (submit.length > 0) {
                submit.spinnerButton('start');
            }
            $.ajax({
                'url': action,
                'type': 'POST',
                'data': form.serialize(),
                'async': true,
                complete: function (data, textStatus) {
                    var response = data.responseText;

                    if (window.jordanb2b.util.isJSON(response)) {
                        var jsonResponse = JSON.parse(response);

                        if (jsonResponse.error) {
                            form.find('.alert').addClass('hidden');
                            form.find('.alert-danger').html(jsonResponse.error).removeClass('hidden');
                            if ('forgotpassword' === jsonResponse.type) {
                                $('#passwordForget').find('input').not('[type="checkbox"],[type="radio"]').addClass('error');
                                $('#passwordForget').find('label').addClass('control-label');
                                window.jordanb2b.util.updateFormStyles($('#modal-container .modal-content'));
                            }
                        } else {
                            if ('login' === jsonResponse.type) {
                                if (jsonResponse.template && 'login' === jsonResponse.section) {
                                    if (true === isModal) {
                                        $('#login', '#modal-container').replaceWith(jsonResponse.template);
                                        window.jordanb2b.util.updateFormStyles($('#modal-container .modal-content'));
                                    } else {
                                        form.replaceWith(jsonResponse.template);
                                    }
                                    return true;
                                } else {
                                    window.jordanb2b.Settings.Customer.isLoggedIn = true;
                                    if (true === isModal) {
                                        $('.load-login-modal').removeClass('load-login-modal');
                                    }

                                    if (jsonResponse.redirect) {
                                        let redirectUrl = new URL(jsonResponse.redirect);
                                        if (redirectUrl.hash) {
                                            window.location.href = jsonResponse.redirect;
                                            window.location.reload();
                                            return true;
                                        }

                                        window.location.href = jsonResponse.redirect;
                                        return true;
                                    }

                                    if ($('.reload-after-login').length > 0) {
                                        var obj = URI(window.location.href);
                                        window.location.href = obj.toString();
                                        return true;
                                    }

                                    if (window.location.pathname == '/') {
                                        window.location.reload();
                                        return true;
                                    }

                                    if (jsonResponse.templates) {
                                        $.each(jsonResponse.templates.insert, function (index, value) {
                                            if ($(index).length > 0) {
                                                $(index).html(value);
                                            }
                                        });
                                        $.each(jsonResponse.templates.replace, function (index, value) {
                                            if ($(index).length > 0) {
                                                $(index).replaceWith(value);
                                            }
                                        });
                                    }
                                    $.initCalculator();
                                    $.initMainMenu();
                                    window.jordanb2b.util.initSwiper();

                                    //epoq
                                    if ($('#epoq_typeaheadbox').length > 0) {
                                        EpoqSearch.initTranslation();
                                        EpoqSearch.initSearch();
                                    }
                                }
                            }
                            if ('forgotpassword' == jsonResponse.type) {
                                $('#passwordForget', '#modal-container').replaceWith(jsonResponse.template);
                                window.jordanb2b.util.updateFormStyles($('#modal-container .modal-content'));
                                return true;
                            }
                            if (true === isModal) {
                                callbackExecutable = true;
                                $('#modal-container').modal('hide');
                            }
                        }
                        if (true === isModal) {
                            window.jordanb2b.util.updateFormStyles($('#modal-container .modal-content'));
                        }
                        callbacks.fire('modal.submit.complete', jsonResponse);
                        return true;
                    } else {
                        if (target && ($('#' + target).length > 0 || $('.' + target).length > 0)) {
                            $('#' + target + ',.' + target).html(response);
                        } else {
                            $('#modal-container .modal-content').html(response);
                            if (title) {
                                $('#modal-container .modal-content .modal-title').html(title);
                            }
                            window.jordanb2b.util.updateFormStyles($('#modal-container .modal-content'));
                            if ($('#modal-container .modal-content').find('.alert-danger').length > 0) {
                                callbackExecutable = false;
                            } else {
                                callbackExecutable = true;
                            }
                        }
                    }
                    callbacks.fire('modal.submit.complete', response);
                    return true;
                }
            }).always(function () {
                if (disable === true) {
                    $inputFields.prop('readonly', false);
                }
                if (submit.length > 0) {
                    submit.spinnerButton('stop');
                }
            });
            return false;
        },
        checkForGridStackConfigsAndReplace: function () {
            return this.each(function (i, elem) {
                var widget = $(elem),
                    x = widget.attr('data-gs-x-saved'),
                    y = widget.attr('data-gs-y-saved'),
                    width = widget.attr('data-gs-width-saved'),
                    height = widget.attr('data-gs-height-saved');
                widget.attr('data-gs-x', x);
                widget.attr('data-gs-y', y);
                widget.attr('data-gs-width', width);
                widget.attr('data-gs-height', height);
            });
        },
        initAlgolia: function () {
            //init autocomplete
            var client = $.algolia.Client(Algolia.applicationId, Algolia.searchApiKey),
                index = client.initIndex(Algolia.indexName),
                facetFilter = Algolia.filter.autocomplete || 'type:product';
            if (typeof jQuery.fn._autocomplete === "undefined") {
                var autocomplete = jQuery.fn.autocomplete.noConflict();
                jQuery.fn._autocomplete = autocomplete;
            }
            $(this)._autocomplete(
                {
                    hint: true
                },
                [
                    {
                        source: $.fn._autocomplete.sources.hits(index, {hitsPerPage: 15, filters: facetFilter}),
                        displayKey: 'name',
                        templates: {
                            suggestion: function (suggestion) {
                                if (suggestion.hasImage) {
                                    return "<img src='" + suggestion.image.original.replace('/upload/', '/upload/product-thumb-b2b/') + "'/><span class='truncate-text'>" + suggestion._highlightResult.name.value + "</span>"
                                }
                                return "<img src='" + jordanb2b.Settings.placeholder + "'/><span class='truncate-text'>" + suggestion._highlightResult.name.value + "</span>"
                            }
                        }
                    }
                ]
            ).on('autocomplete:selected', function (event, suggestion, dataset, context) {
                if (suggestion.link) {
                    var link = suggestion.link;
                    if (jordanb2b.Settings.DM) {
                        link = suggestion.link_raw;
                    }
                    window.location.assign(link);
                    return;
                }
                return;
            });
            $(this).attr('autocomplete', 'off');
        },
        initFileUpload: function () {
            var form = $(this),
                input = form.find('input.file-input'),
                attachments = form.find('input.inquire-attachment');

            var initialPreview = [],
                initialPreviewConfig = [];

            if (attachments.length > 0) {
                $.each(attachments, function (key, input) {
                    var el = $(input),
                        name = el.val(),
                        content = el.data('content'),
                        url = el.data('url'),
                        size = el.data('size'),
                        type = el.data('type'),
                        filetype = el.data('filetype'),
                        config = {};

                    if ('text' === type && content) {
                        initialPreview.push(content);
                    } else {
                        initialPreview.push(url);
                    }
                    config = {caption: name, key: key, size: size, url: url, type: type, filetype: filetype};
                    initialPreviewConfig.push(config);
                });
            }

            if (input.length > 0) {
                input.fileinput({
                    language: jordanb2b.Settings.Language || 'de',
                    theme: 'fa',
                    allowedFileTypes: ['image', 'text', 'pdf'],
                    initialPreviewAsData: true,
                    initialPreview: initialPreview,
                    initialPreviewConfig: initialPreviewConfig,
                    showUpload: false, // hide upload button
                    fileActionSettings: {
                        showUpload: false,
                        showDrag: false
                    }
                    //browseOnZoneClick: true,
                });

                if (input.data('initial-caption').length > 0) {
                    window.setTimeout(function () {
                        input.closest('div.file-input').removeClass('file-input-new');
                    }, 250);
                }
            }
        },
        initFilePond: function () {
            let form = $(this),
                inputs = form.find('input[type="file"].filepond');

            inputs.each(function () {
                let attachments = form.find('input[name="' + $(this).attr('name').replace('[]', '') + '_data_attachment[]"]'),
                    files = [];

                attachments.each(function () {
                    files.push({
                        source: $(this).data('content'),
                        options: {
                            type: 'local',
                            file: {
                                name: $(this).val(),
                                size: $(this).data('size'),
                                type: $(this).data('type')
                            },
                        },
                    });
                });

                // Create a FilePond instance
                const pond = FilePond.create(this, {
                    storeAsFile: true,
                    allowMultiple: true,
                    credits: false,
                    files: files,
                });

                pond.on('removefile', (error, file) => {
                    console.log(error);
                    console.log(file.filename);
                    attachments.each(function () {
                        if ($(this).val() === file.filename) {
                            $(this).remove();
                        }
                    });
                });

                let locale = window.jordanb2b.Settings.Language.slice(0, 2)
                FilePond.setOptions(filepond_locale[locale] || filepond_locale['en']);
            });
        }
    });

    $.fn.select2.amd.require(
        ['select2/dropdown/attachBody'],
        function (AttachBody) {
            AttachBody.prototype._positionDropdown = (function (originalMethod) {
                return function () {
                    var $window = $(window);

                    var isCurrentlyAbove = this.$dropdown.hasClass('select2-dropdown--above');
                    var isCurrentlyBelow = this.$dropdown.hasClass('select2-dropdown--below');

                    var newDirection = null;

                    var offset = this.$container.offset();

                    offset.bottom = offset.top + this.$container.outerHeight(false);

                    var container = {
                        height: this.$container.outerHeight(false)
                    };

                    container.top = offset.top;
                    container.bottom = offset.top + container.height;

                    var dropdown = {
                        height: this.$dropdown.outerHeight(false)
                    };

                    var viewport = {
                        top: $window.scrollTop(),
                        bottom: $window.scrollTop() + $window.height()
                    };

                    var enoughRoomAbove = viewport.top < (offset.top - dropdown.height);
                    var enoughRoomBelow = this.options.get('forcebelow') || viewport.bottom > (offset.bottom + dropdown.height);

                    var css = {
                        left: offset.left,
                        top: container.bottom
                    };

                    // Determine what the parent element is to use for calciulating the offset
                    var $offsetParent = this.$dropdownParent;

                    // For statically positoned elements, we need to get the element
                    // that is determining the offset
                    if ($offsetParent.css('position') === 'static') {
                        $offsetParent = $offsetParent.offsetParent();
                    }

                    var parentOffset = $offsetParent.offset();

                    css.top -= parentOffset.top;
                    css.left -= parentOffset.left;

                    if (!isCurrentlyAbove && !isCurrentlyBelow) {
                        newDirection = 'below';
                    }

                    if (!enoughRoomBelow && enoughRoomAbove && !isCurrentlyAbove) {
                        newDirection = 'above';
                    } else if (!enoughRoomAbove && enoughRoomBelow && isCurrentlyAbove) {
                        newDirection = 'below';
                    }

                    if (newDirection == 'above' ||
                        (isCurrentlyAbove && newDirection !== 'below')) {
                        css.top = container.top - parentOffset.top - dropdown.height;
                    }

                    if (newDirection !== null) {
                        this.$dropdown
                            .removeClass('select2-dropdown--below select2-dropdown--above')
                            .addClass('select2-dropdown--' + newDirection);
                        this.$container
                            .removeClass('select2-container--below select2-container--above')
                            .addClass('select2-container--' + newDirection);
                    }

                    this.$dropdownContainer.css(css);
                };
            }(AttachBody.prototype._positionDropdown));
        },
        undefined,
        true
    );
}(jQuery));

(function (factory) {
    "use strict";
    if (typeof exports === 'object') {
        module.exports = factory(window.jQuery);
    } else if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (window.jQuery && !window.jQuery.fn.jddatatable) {
        factory(window.jQuery);
    }
}
(function ($) {
    'use strict';

    var defaults = {
        lengthChange: true,
        order: [],
        orderColumn: 0,
        processing: false,
        serverSide: false,
        searching: true,
        type: 'normal',
        url: null,
        columns: [],
        columnDefs: [],
        pageLength: 10,
        autoWidth: true,
        responsive: false,
        dom: "<'row'<'col-sm-4'l><'col-sm-4 text-center'B><'col-sm-4'f>>tp"
    };

    var JdDataTable = function (element, options) {
        this.element = $(element);
        this.options = $.extend(true, {}, defaults, this.element.data(), options);
        if (this.options.url && 'ajax' != this.options.type) {
            this.options.type = 'ajax';
        }

        if ($(window).width() < 768) {
            this.options.dom = "<'row'<'col-xs-12 col-sm-4'f><'col-xs-12 col-sm-4'l><'col-xs-12 col-sm-4 text-center'B>>tp";
        }

        //columnNames + columnClass
        var tableColumns = [];
        $('th', this.element).each(function () {
            var columnName = $(this).data('column-name'),
                columnClass = $(this).data('column-class'),
                columnData = {};
            if (columnName && columnName.length > 0) {
                if ('active' == columnName || 'status' == columnName) {
                    columnData.data = columnName;
                    columnData.orderable = false;
                    columnData.searchable = false;
                } else {
                    columnData.data = columnName;
                }
            }
            if (columnClass && columnClass.length > 0) {
                columnData.className = columnClass;
            } else {
                columnData.className = null;
            }
            tableColumns.push(columnData);
        });
        this.options.columns = tableColumns;

        //columnDefs
        var columnDefs = [];
        columnDefs.push({
            "targets": 'no-sort',
            "orderable": false,
            'searchable': false
        });
        columnDefs.push({
            "targets": 'no-visible',
            "visible": false
        });

        $('th', this.element).each(function (index) {
            if ($(this).data('column-sort')) {
                columnDefs.push({type: $(this).data('column-sort'), targets: index});
            }
        });

        this.options.columnDefs = columnDefs;

        //create
        this.create();
    };
    JdDataTable.prototype = {
        constructor: JdDataTable,
        create: function () {
            var that = this;
            this.element.dataTable({
                processing: this.options.processing,
                serverSide: this.options.serverSide,
                ajax: this.options.url,
                lengthChange: this.options.lengthChange,
                searching: this.options.searching,
                order: this.options.order,
                columns: this.options.columns.length > 0 ? this.options.columns : null,
                pageLength: this.options.pageLength,
                autoWidth: this.options.autoWidth,
                responsive: this.options.responsive,
                dom: this.options.dom,
                buttons: [
                    {extend: 'copy', className: 'btn-sm'},
                    {extend: 'csv', className: 'btn-sm'},
                    {extend: 'pdf', className: 'btn-sm'},
                    {extend: 'print', className: 'btn-sm'}
                ],
                columnDefs: this.options.columnDefs,
                language: {
                    'url': $(window).width() < 768 ? '/static/de.jordan.b2b.theme/assets/js/libs/datatables/i18n/de-de-768.json' : '/static/de.jordan.b2b.theme/assets/js/libs/datatables/i18n/de-de.json'
                },
                drawCallback: function (settings) {
                    //pagination
                    var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate'),
                        showPagination = this.api().page.info().pages > 1;
                    pagination.toggle(showPagination);
                    //length change
                    var length = $(this).closest('.dataTables_wrapper').find('.dataTables_length'),
                        showLength = this.api().page.info().pages > 1;
                    length.toggle(showLength);
                }
            });
        }
    };

    $.jddatatable = JdDataTable;

    $.fn.jddatatable = function (option) {
        var salesArgs, rv;
        var $returnValue = this.each(function () {
            var $this = $(this),
                inst = $this.data('jddatatable'),
                options = ((typeof option === 'object') ? option : {});
            if ((!inst) && (typeof option !== 'string')) {
                $this.data('jddatatable', new JdDataTable(this, options));
            } else {
                if (typeof option === 'string') {
                    rv = inst[option].apply(inst, Array.prototype.slice.call(salesArgs, 1));
                }
            }
        });
        if (option === 'getValue') {
            return rv;
        }
        return $returnValue;
    };

    $.fn.jddatatable.constructor = JdDataTable;

}));
/**
 * This plug-in will provide numeric sorting for currency columns (either
 * detected automatically with the currency type detection plug-in or set
 * manually) while taking account of the currency symbol ($ or Â£ by default).
 *
 * DataTables 1.10+ has currency sorting abilities built-in and will be
 * automatically detected. As such this plug-in is marked as deprecated, but
 * might be useful when working with old versions of DataTables.
 *
 *  @name Currency
 *  @summary Sort data numerically when it has a leading currency symbol.
 *  @deprecated
 *  @author [Allan Jardine](http://sprymedia.co.uk)
 *
 *  @example
 *    $('#example').dataTable( {
 *       columnDefs: [
 *         { type: 'currency', targets: 0 }
 *       ]
 *    } );
 */

jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    "currency-pre": function (a) {
        //EUR
        if (a.indexOf("€") != -1) {
            a = (a === "-") ? 0 : a.replace(/[^\d\-\,]/g, "").replace(',', '.');
        } else {
            //default
            a = (a === "-") ? 0 : a.replace(/[^\d\-\.]/g, "");
        }
        return parseFloat(a);
    },

    "currency-asc": function (a, b) {
        return a - b;
    },

    "currency-desc": function (a, b) {
        return b - a;
    }
});

/**
 * This plug-in for DataTables represents the ultimate option in extensibility
 * for sorting date / time strings correctly. It uses
 * [Moment.js](http://momentjs.com) to create automatic type detection and
 * sorting plug-ins for DataTables based on a given format. This way, DataTables
 * will automatically detect your temporal information and sort it correctly.
 *
 * For usage instructions, please see the DataTables blog
 * post that [introduces it](//datatables.net/blog/2014-12-18).
 *
 * @name Ultimate Date / Time sorting
 * @summary Sort date and time in any format using Moment.js
 * @author [Allan Jardine](//datatables.net)
 * @depends DataTables 1.10+, Moment.js 1.7+
 *
 * @example
 *    $.fn.dataTable.moment( 'HH:mm MMM D, YY' );
 *    $.fn.dataTable.moment( 'dddd, MMMM Do, YYYY' );
 *
 *    $('#example').DataTable();
 */

(function (factory) {
    if (typeof define === "function" && define.amd) {
        define(["jquery", "moment", "datatables.net"], factory);
    } else {
        factory(jQuery, moment);
    }
}(function ($, moment) {

    $.fn.dataTable.moment = function ( format, locale ) {
        var types = $.fn.dataTable.ext.type;

        // Add type detection
        types.detect.unshift( function ( d ) {
            if ( d ) {
                // Strip HTML tags and newline characters if possible
                if ( d.replace ) {
                    d = d.replace(/(<.*?>)|(\r?\n|\r)/g, '');
                }

                // Strip out surrounding white space
                d = $.trim( d );
            }

            // Null and empty values are acceptable
            if ( d === '' || d === null ) {
                return 'moment-'+format;
            }

            return moment( d, format, locale, true ).isValid() ?
                'moment-'+format :
                null;
        } );

        // Add sorting method - use an integer for the sorting
        types.order[ 'moment-'+format+'-pre' ] = function ( d ) {
            if ( d ) {
                // Strip HTML tags and newline characters if possible
                if ( d.replace ) {
                    d = d.replace(/(<.*?>)|(\r?\n|\r)/g, '');
                }

                // Strip out surrounding white space
                d = $.trim( d );
            }

            return d === '' || d === null ?
                -Infinity :
                parseInt( moment( d, format, locale, true ).format( 'x' ), 10 );
        };
    };

}));
$(document).ready(function () {

    $.initCalculator();
    $.configDatepicker();
    $('.match-height').matchHeight();
    $('.match-height-row').matchHeight({byRow: true});
    $('header .location .jd-popup-box-col').matchHeight({byRow: true});

    window.jordanb2b.util.initAll();
    window.jordanb2b.util.initDocumentHash();

    /******************************************************************************************************************
     * scroll to defined anchor with animation
     *****************************************************************************************************************/
    $('a[href*="#"]:not([href="#"])').not('.disable-scrolling').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            if ($($(this).attr('href')).length == 0) {
                /*var target = $("#startProducts");
                 target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                 if (target.length) {
                 $('html, body').animate({
                 scrollTop: target.offset().top - 100
                 }, 1000);
                 }*/
                window.jordanb2b.util.scroll();
            } else {
                window.location.hash = $(this).attr('href');
            }
        }
    });

    $(".accordion").on("hide.bs.collapse", function (event) {
        event.stopPropagation();
    });

    //datatables
    $.fn.dataTable.moment('DD.MM.YYYY');
    $('table.jddatatable').jddatatable();

    // last viewed products slider
    var slickOptionsLastViewedProducts = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 12,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 10
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 8
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 6
                }
            }
        ]
    };

    function initTooltipInSlick() {
        $('[data-toggle="tooltip-view-last"]').tooltip({
            container: 'body',
            placement: 'bottom',
        });
    }

    $('.slider-viewed-products:visible')
        .slick(slickOptionsLastViewedProducts)
        .on('afterChange', function (event, slick, direction) {
            initTooltipInSlick();
        });
    initTooltipInSlick();

    //scrollToTop
    if ($(window).width() < 768) {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('.scrollToTop').fadeIn();
            } else {
                $('.scrollToTop').fadeOut();
            }
        });

        //Click event to scroll to top
        $('.scrollToTop').click(function () {
            $('html, body').animate({scrollTop: 0}, 800);
            return false;
        });
    }
}).on('click', '.check-login-status', function (event) {
    var me = $(this);
    if (false === me.checkLoginModal(event)) {
        return false;
    }
}).on({
    ajaxStart: function () {
        //window.jordanb2b.util.showLoadingOverlay();
    },
    ajaxStop: function () {
        //window.jordanb2b.util.hideLoadingOverlay();
    }
});

$(window).on('hashchange', function () {
    window.jordanb2b.util.initDocumentHash();
});